<script setup>
import PackliCard from "../Packli/PackliCard.vue";
import ConfiguratorCard from "../Packli/ConfiguratorCard.vue";
import GiftCard from "../Packli/GiftCard.vue";
import MainRegionCard from "../Packli/MainRegionCard.vue";
import {onMounted, nextTick} from "vue";

let ScrollReveal;
const isClient = typeof window === 'object';


const props = defineProps({
    packlis: Array,
    header: String
});

onMounted(async () => {
    if (isClient) {
        ScrollReveal = (await import('scrollreveal')).default;
        nextTick(() => {
            scrollProductReveal();
        });
    }
});

const scrollProductReveal = () => {
    if (isClient && ScrollReveal) {
        ScrollReveal().reveal('.product-card', {
            origin: 'top',
            distance: '0px',
            duration: 500,
            delay: 300,
            scale: 1,
            opacity: 0,
            mobile: false,
            reset: false,
            easing: 'ease'
        });
    }
}

</script>

<template>
    <div class="container animated-top" id="paeckli">
        <header class="page-header">
            <h2>{{ header }}</h2>
        </header>
        <div class="products">
            <PackliCard v-for="packli in packlis"
                        :key="packli.id" :packli="packli"/>
            <ConfiguratorCard/>
            <GiftCard/>
            <MainRegionCard/>
        </div>
    </div>
</template>

<style scoped>

</style>
