// src/constants.js

export const SHIPMENT_TYPE_DELIVERY = "delivery";
export const SHIPMENT_TYPE_PICKUP = "pickup";
export const ITEM_TYPE_PACKAGE = "package";
export const ITEM_TYPE_CUSTOM_PACKAGE = "custom_package";
export const ITEM_TYPE_GIFT = "gift_card";

export const PAYMENT_METHOD_PAYREXX = "payrexx";

export const PAYMENT_METHOD_INVOICE = "invoice";
