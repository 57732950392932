<script setup>

import {watch} from "vue";
import {PackliConfiguratorStore} from "@/Store/PackliConfigurator";

const properties = defineProps({
    product: Object,
    index: Number,
});

const showProductDetail = () => {
    emits('showProductDetail', properties.index);
};

const emits = defineEmits(['showProductDetail']);

const store = PackliConfiguratorStore();

watch(() => properties.product.quantity, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        store.updateProductQuantitySequence(properties.product.categoryId, properties.product.id);
    }
});

</script>

<template>
    <div class="product-item">
        <div class="wrap">
            <figure :style="{ backgroundImage: 'url(' + product.images[0] + ')' }" class="bg-img img">
            </figure>
            <div class="descr">
                <h4>{{ product.name }}</h4>
                <p>{{ product.description }}</p>
            </div>
        </div><!-- / wrap -->
        <footer>
            <a class="btn btn-success open-content-popup" href="#" @click="showProductDetail">{{
                    $t('Info ansehen')
                }}</a>
            <div :class="{'inactive': product.quantity === 0}" class="qty-box">
                <input v-model="product.quantity"
                       class="form-control"
                       placeholder=""
                       type="text">
                <a class="up"
                   href="#"
                   @click.prevent="product.quantity++">{{ $t('mehr Produkte') }}</a>
                <a class="down"
                   href="#"
                   @click.prevent="product.quantity > 0 ? product.quantity-- : null">{{ $t('weniger Produkte') }}</a>
            </div>
        </footer>
    </div><!-- / product-item -->
</template>

<style scoped>
.up, .down {
    cursor: pointer;
}
</style>
