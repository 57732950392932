<script setup>
import {defineProps} from 'vue'

const props = defineProps({
  item: Object
})

const emit = defineEmits(['onDeleteFromCart'])

const onDeleteFromCart = (id) => {
  emit('onDeleteFromCart', id);
}

const editGiftCard = (item) => {
  emit('onEditGiftCard', item);
}
</script>

<template>
  <div class="order-cell base-cell giftcard-first">
    <header>
      <div class="hold">
        <h3 v-text="$t('Geschenkgutschein')"></h3>
        <a @click="onDeleteFromCart(item.uuid)"
           href="#"
           class="btn-delete">
          <i class="fa fa-trash-o" aria-hidden="true"></i>
          <span class="sr-only" v-text="$t('Element entfernen')"></span>
        </a>
      </div>
    </header>
    <div class="pack-list">
      <p v-text="$t('Geschenkgutschein')"></p>
    </div>
  </div>
  <div class="header-group fourth">
    <div class="order-cell type2">
      <header>
        <h3 v-text="$t('PREIS')"></h3>
      </header>
      <strong class="price">
        CHF {{ item.price }}
      </strong>
    </div>
    <div class="order-cell hidden-xs hidden-sm">
      <header>
        <a @click.prevent="editGiftCard(item)"
           href="#"
           class="link">{{ $t('Ändern') }}</a>
      </header>
      <a @click.prevent="onDeleteFromCart(item.uuid)"
         href="#"
         class="btn-delete">
        <i class="fa fa-trash-o" aria-hidden="true"></i>
        <span class="sr-only" v-text="('Element entfernen')"></span>
      </a>
    </div>
  </div>
  <a @click="editGiftCard(item.uuid, item.packli_object.price)" href="#"
     class="btn btn-block btn-default hidden-md hidden-lg">
      {{ $t('Ändern') }}
  </a>
</template>
