<script setup>
import Header from "../Components/Layouts/Header.vue";
import Footer from "../Components/Layouts/Footer.vue";
import SubscriptionForm from "../Components/Layouts/SubscriptionForm.vue";
import {computed, onMounted, onUnmounted, provide, ref, nextTick} from 'vue';
import {Head, usePage} from '@inertiajs/vue3';
import SimpleFooter from "../Components/Layouts/SimpleFooter.vue";
import {router} from '@inertiajs/vue3'

let ScrollReveal;
let scrollRevealInstance;
const isClient = typeof window === 'object';

const props = defineProps({
    showSubscriptionForm: {
        type: Boolean,
        default: true
    },
    simpleFooter: {
        type: Boolean,
        default: false
    },
});

const region = computed(() => usePage().props.region);
const regions = computed(() => usePage().props.regions);
const mainRegion = computed(() => usePage().props.mainRegion);
const isMainRegion = computed(() => usePage().props.isMainRegion);
const pageData = computed(() => usePage().props.page_data);
const subRegions = computed(() => usePage().props.subRegions);
const gtmData = computed(() => usePage().props.gtm_data);

provide('regions', regions);
provide('region', region);
provide('mainRegion', mainRegion);
provide('isMainRegion', isMainRegion);
provide('subRegions', subRegions);

let scrollTop = ref(0);

const onScroll = () => {
    if (typeof window !== 'undefined') {
        let newScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (newScrollTop > 121 && scrollTop.value < newScrollTop) {
            document.body.classList.add('scrolled');
        } else {
            document.body.classList.remove('scrolled');
        }
        scrollTop.value = newScrollTop;
    }
};

let startEventListener = null;

onMounted(() => {
    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', onScroll);
    }
    if (typeof window !== 'undefined') {
        scrollToFragment();
    }

    nextTick(async () => {
        if (isClient) {
            ScrollReveal = (await import('scrollreveal')).default;

            if (!pageData.value || pageData.value.slug !== 'paeckli') {
                scrollRevealInstance = ScrollReveal().reveal('.animated-top', {
                    origin: 'top',
                    distance: '0px',
                    duration: 500,
                    delay: 300,
                    scale: 1,
                    opacity: 0,
                    mobile: false,
                    reset: false,
                    easing: 'ease'
                });
            } else {
                if (scrollRevealInstance) {
                    scrollRevealInstance.destroy();
                    scrollRevealInstance = null;
                }
            }
        }
    });

    if (typeof window !== 'undefined') {
        startEventListener = router.on('start', (event) => {
            if (typeof window.dataLayer !== 'undefined') {
                window.dataLayer.push({
                    'event': 'pageview',
                    'page': event.detail.visit.url.href
                });
            }
        })
    }
});

onUnmounted(() => {
    if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', onScroll);

        if (startEventListener) {
            startEventListener();
        }
    }
    cleanupListeners();
});


function scrollToFragment() {
    const handleAnchorClick = (event) => {
        if (typeof window !== 'undefined') {
            const href = event.target.getAttribute('href');
            if (href && href.startsWith('#') && href.length > 1) {
                event.preventDefault();
                const element = document.querySelector(href);
                if (element) {
                    element.scrollIntoView({behavior: 'smooth'});
                }
            }
        }
    };

    if (typeof window !== 'undefined') {
        document.addEventListener('click', handleAnchorClick);
    }

    return handleAnchorClick;
}

function cleanupListeners() {
    if (typeof window !== 'undefined') {
        document.removeEventListener('click', scrollToFragment());
    }
}

if (gtmData.value) {
    if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push(gtmData.value.gtm);
    }
}

</script>


<template>
    <Head>
        <title>{{pageData.meta_title}}</title>
        <meta name="description" :content="pageData.meta_description">

        <meta name="description" :content="pageData.meta_description">
        <meta name="title" :content="pageData.meta_title">
        <meta name="author" :content="pageData.website_title ">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">

        <!-- for Facebook -->
        <meta property="og:title" :content="pageData.meta_title"/>
        <meta property="og:type" content="article"/>
        <meta property="og:image" :content="pageData.meta_image"/>
        <meta property="og:url" content=""/>
        <meta property="og:description" :content="pageData.meta_description"/>

        <!-- for Twitter -->
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" :content="pageData.meta_title"/>
        <meta name="twitter:description" :content="pageData.meta_description"/>
        <meta name="twitter:image" :content="pageData.meta_image"/>

    </Head>
    <div class="wrapper">
        <div>
            <Header/>
        </div>
        <div>
            <slot/>
        </div>
        <template v-if="!simpleFooter">
            <SubscriptionForm/>
            <Footer/>
        </template>
        <template v-else>
            <SimpleFooter/>
        </template>
    </div>

</template>
