<script setup>
import {defineProps, onBeforeUnmount, onMounted, ref} from 'vue';

const props = defineProps({
    label: String,
    items: Array,
    defaultLabel: String,
    region_id: Number,
});

const activeItem = ref(null);
const isOpen = ref(false);
const dropdownRef = ref(null);

function setActiveItem(item) {
    activeItem.value = item;
    emits('itemSelected', item);
    isOpen.value = false;
}

function toggleDropdown() {
    isOpen.value = !isOpen.value;
}

onMounted(() => {
    if (typeof window !== 'undefined') {
        document.addEventListener('click', handleClickOutside);
    }
})

onBeforeUnmount(() => {
    if (typeof window !== 'undefined') {
        document.removeEventListener('click', handleClickOutside)
    }
})

function handleClickOutside(event) {
    if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
        isOpen.value = false
    }
}

const emits = defineEmits(['itemSelected']);

activeItem.value = props.items.find(item => item.id === props.region_id) || props.items[0];
</script>

<template>
    <div ref="dropdownRef" :class="{ open: isOpen }" class="dropdown">
        <a class="dropdown-button" data-toggle="dropdown" @click="toggleDropdown">
            <span class="small-str">{{ label }}</span>
            <span class="str">
        {{ activeItem ? activeItem.name : defaultLabel }}
      </span>
            <span class="caret"></span>
        </a>
        <ul class="dropdown-menu">
            <li v-for="item in items" :key="item.id"
                :class="{ active: activeItem && activeItem.id === item.id }">
                <a :data-item="item.id" @click.prevent="setActiveItem(item)">{{ item.name }}</a>
            </li>
        </ul>
    </div>
</template>
