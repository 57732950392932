<script setup>
import {ref} from "vue";
import useVisual from "../../Composable/useVisual.js";

const props = defineProps({
    image: {
        type: String,
        default: ''
    }
});

const textHolder = ref(null);
const bgImage = ref(null);

useVisual(textHolder, bgImage);

</script>

<template>
    <div class="visual">
        <figure class="bg-img" ref="bgImage"
                :style="{backgroundImage: 'url(' + image + ')'}">
            <img :src="image" alt="#"
                 class="fr-fic fr-dii hidden">
            <slot name="action"></slot>
        </figure>
        <div class="holder" ref="textHolder">
            <div class="container">
                <slot></slot>
            </div>
            <!-- / container -->
        </div>
        <!-- / holder -->
    </div>
</template>

<style scoped>

</style>
