<script setup>
import {computed, nextTick, onMounted, ref} from "vue";
import PackliCard from "../Packli/PackliCard.vue";
import ConfiguratorCard from "../Packli/ConfiguratorCard.vue";
import GiftCard from "../Packli/GiftCard.vue";
import PackliListFilters from "../ListPacklis/PackliListFilters.vue";

let ScrollReveal;
const isClient = typeof window === 'object';

let scrollRevealInstance;

const props = defineProps({
    packlis: {
        type: Array,
        required: true,
    },
    regions: {
        type: Array,
        required: true,
    },
    minPrice: {
        type: Number,
        required: true,
    },
    maxPrice: {
        type: Number,
        required: true,
    },
    header: {
        type: String,
        required: true,
    },
    sub_header: {
        type: String,
        required: true,
    },
    content: {
        type: String,
        required: true,
    },
    filter_region: {
        type: Number,
        required: false,
    },
});

const filter = ref({
    region_id: props.filter_region ? props.filter_region : -1,
    min_price: props.minPrice,
    max_price: props.maxPrice,
});

const filterPacklis = computed(() => {
    return props.packlis.filter((packli) => {
        return (
            (filter.value.region_id === -1 || packli.region_id === filter.value.region_id) &&
            (packli.price >= filter.value.min_price && packli.price <= filter.value.max_price)
        );
    });
});

onMounted(async () => {
    if (isClient) {
        ScrollReveal = (await import('scrollreveal')).default;
        scrollProductReveal();
    }
});

const onFilterChange = (value) => {
    nextTick(async () => {

        //scroll to first .product-card

        const firstProductCard = document.querySelector('.product-card');
        if (firstProductCard) {
            //as we have a fixed header, we need to scroll a bit more
            const firstProductCardTop = firstProductCard.getBoundingClientRect().top;

            let height = 0;

            //also consider filter-drop down height
            const filterDropDown = document.querySelector('.filter-wrap');
            if (filterDropDown) {
                height += filterDropDown.offsetHeight;
            }

            //add header height if body dosn't have scrolled class
            if (!document.body.classList.contains('scrolled')) {
                const header = document.querySelector('#header');
                if (header) {
                    height += header.offsetHeight;
                }
            }

            /*window.scrollTo({
                top: firstProductCardTop + window.scrollY - height,
                behavior: 'smooth',
            });*/

            //scrollProductReveal();
            //scrollRevealInstance.sync();
        }
    });
};

const scrollProductReveal = () => {
    if (isClient && ScrollReveal) {
        if (scrollRevealInstance) {
            scrollRevealInstance.destroy();
            scrollRevealInstance = null;
        }

        scrollRevealInstance = ScrollReveal({
            origin: 'top',
            distance: '200px',
            duration: 500,
            delay: 300,
            scale: 1,
            opacity: 0,
            mobile: false,
            reset: true,
            easing: 'ease'
        });

        //scrollRevealInstance.reveal('.main-region-packli');
    }
}

</script>

<template>
    <div class="container">
        <div class="packli_content">
            <h1>{{ header }}</h1>
            <h2>{{ sub_header }}</h2>
            <p v-html="content"></p>
        </div>
    </div>

    <div class="products-area">
        <div class="container">
            <PackliListFilters
                :filters="filter"
                :regions="regions"
                @filterChanged="onFilterChange"/>
            <div id="packageList" class="products">
                <PackliCard v-for="packli in filterPacklis"
                            :key="packli.id"
                            :packli="packli"
                            :show-region-logo="true" class="main-region-packli"/>
                <ConfiguratorCard v-if="filter.region_id === -1 || filter.region_id === 0" class="main-region-packli"/>
                <GiftCard v-if="filter.region_id === -1 || filter.region_id === 0" class="main-region-packli"/>
            </div>
        </div><!-- / container -->
    </div><!-- / products-area -->
</template>

<style scoped>
.packli_content {
    padding-top: 30px;
}

.packli_content h1 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 28px
}

.packli_content h2 {
    margin-top: 8px;
    margin-bottom: 8px
}

</style>
