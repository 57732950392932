<script setup>
import {computed, inject} from 'vue';
import {Link, usePage} from "@inertiajs/vue3";
import {useI18n} from "vue-i18n";

const {t} = useI18n();


const regions = inject('regions');
const subRegions = inject('subRegions');
const region = inject('region');
const mainRegion = inject('mainRegion');
const isMainRegion = inject('isMainRegion');
const settings = computed(() => usePage().props.settings);

const logoStyle = computed(() => {
    return {
        background: `url(${region.value.logo}) no-repeat`,
        backgroundSize: '100% auto',
    };
});

const footerPages = [
    {id: 1, title: t('Häufige Fragen'), route: 'faq', url: '/fragen-und-antworten'},
    {id: 2, title: t('Produzenten'), route: 'suppliers', url: '/produzenten'},
    {id: 3, title: t('Impressum'), route: 'imprint', url: '/impressum'},
    {id: 4, title: t('Geschäftskunden'), route: 'business-customers', url: '/geschaeftskunden'},
    {id: 5, title: t('AGB'), route: 'agb', url: '/agb'},
    {id: 6, title: t('Über uns'), route: 'about-us', url: '/ueber-uns'},
    {id: 7, title: t('Datenschutz'), route: 'data-protection', url: '/datenschutz'},
    {id: 8, title: t('Produktdeklaration'), route: 'product-declaration', url: '/produktdeklaration'},
];

const socialLinks = [
    {id: 1, name: 'facebook', icon: 'fa fa-facebook', url: settings.value['facebook']},
    {id: 2, name: 'youtube', icon: 'fa fa-youtube-play', url: settings.value['youtube']},
    {id: 3, name: 'instagram', icon: 'fa fa-instagram', url: settings.value['instagram']},
];

</script>

<template>
    <footer id="footer" class="animated-top">
        <div class="top-part">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-2 col-lg-3">
                        <strong :style="logoStyle" class="logo">
                            <Link :href="route('home')"><span class="sr-only">{{ region.name }}</span></Link>
                        </strong>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <h4 class="hidden-xs">{{ region.name }}</h4>
                        <ul class="footer-nav">
                            <li>
                                <Link :href="isMainRegion ? route('packli.index') : route('home')+ '#paeckli'">
                                    {{ $t('Zu den Päckli') }}
                                </Link>
                            </li>
                            <li v-for="page in footerPages"
                                :key="page.id"
                                :class="{'active': route().current(page.route)}">
                                <Link :href="page.url">{{ $t(page.title) }}</Link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h4>{{ $t('Kontaktiere uns') }}</h4>
                        <p>
                            <a :href="'mailto:' + settings['contact_email']">{{ settings['contact_email'] }}</a>
                        </p>
                        <p>
                            <a :href="'tel:'+settings['contact_number']">{{ settings['contact_number'] }}</a>
                            <br/>
                            <sup><a :href="settings['whatsapp']" target="_blank">{{
                                    $t('auch auf WhatsApp erreichbar')
                                }}</a></sup>
                        </p>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-2">
                        <h4>{{ $t('FOLGE UNS') }}</h4>
                        <ul class="socials">
                            <li v-for="social in socialLinks" :key="social.id">
                                <a :href="social.url" target="_blank">
                                    <i :class="social.icon" aria-hidden="true"></i>
                                    <span class="sr-only">{{ social.name }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-part">
            <div class="container">
                <div class="row row-flex row-vm">
                    <div class="col-md-3">
                        <p class="by">
                            {{ $t('Ein Produkt von') }}
                            <br/>
                            <strong><a :href="mainRegion.url">{{ mainRegion.name }}</a></strong>
                        </p>
                    </div>
                    <div class="col-md-9">
                        <ul class="bottom-nav">
                            <li v-for="region in subRegions" :key="region.url">
                                <a :href="region.url">{{ region.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
