<script setup>

import MainRegion from "../Layouts/MainRegion.vue";
import DeliveryAddressForm from "../Components/PackliShipmentDetail/DeliveryAddressForm.vue";
import {computed, ref} from "vue";
import {Link, router, usePage} from "@inertiajs/vue3";
import StickyHeader from "../Components/Common/StickyHeader.vue";
import {PackliConfiguratorStore} from "@/Store/PackliConfigurator.js";
import ClientOnly from '@duannx/vue-client-only';

const isCustomPackage = computed(() => usePage().props.is_custom_package);
const isMainRegion = computed(() => usePage().props.isMainRegion);
const isEdit = computed(() => usePage().props.uuid);
const packageRegion = computed(() => usePage().props.package_region);
const showStartAgainModal = ref(false);
const packliConfiguratorStore = PackliConfiguratorStore();
const packliLink = computed(() => usePage().props.packliLink);

const onStartAgainConfirm = () => {
    showStartAgainModal.value = true;
};

const onEditCustomPackage = () => {
    router.get(route('shop.products', {
        region: packageRegion.value.id,
        uuid: isEdit.value ? isEdit.value : null
    }));
};

const onStartAgain = () => {
    showStartAgainModal.value = false;
    packliConfiguratorStore.reset();
    router.get(route('configurator.select-region'));
};

</script>

<template>
    <MainRegion simple-footer>
        <client-only>
            <div class="extra-bar">
                <div class="container">
                    <template v-if="!isEdit">
                        <Link :href="packliLink"
                              class="btn-back">
                            {{ $t('Zurück zur Übersicht') }}
                        </Link>
                    </template>
                    <template v-else>
                        <Link :href="route('checkout')"
                              class="btn-back">
                            {{ $t('Weiter zur Bestellübersicht') }}
                        </Link>
                    </template>
                </div>
            </div>
            <div class="container">
                <h1 v-if="isCustomPackage">{{ $t('Dein Päckli individuell zusammenstellen') }}</h1>
                <div class="steps">
                    <template v-if="isCustomPackage">
                        <header v-if="isMainRegion && !isEdit" class="step-panel completed">
                            <div class="step-panel-holder">
                                <h2>{{ $t('Wähle eine Region aus') }}</h2>
                                <a class="link" href="#" @click.prevent="onStartAgainConfirm">{{ $t('Ändern') }}</a>
                            </div>
                        </header>

                        <header class="step-panel completed">
                            <div class="step-panel-holder">
                                <h2>
                                    <span
                                        v-html="$t('Produkte auswählen<br>Achtung: Individuelle Päckli eignen sich nicht als Geschenk, da nicht alle Produkte in die Geschenkverpackung passen.')"></span>
                                </h2>
                                <a class="link" href="#" @click.prevent="onEditCustomPackage">{{ $t('Ändern') }}</a>
                            </div>
                        </header>
                    </template>

                    <StickyHeader>{{ $t('Lieferinformationen') }}</StickyHeader>

                    <div class="step-entity">
                        <div class="delivery-setup">
                            <DeliveryAddressForm/>
                        </div>
                    </div>

                    <header class="step-panel">
                        <div class="step-panel-holder">
                            <h2>{{ $t('Bestellübersicht') }}</h2>
                        </div>
                    </header>

                    <header class="step-panel">
                        <div class="step-panel-holder">
                            <h2>{{ $t('Bezahlung') }}</h2>
                        </div>
                    </header>
                </div>
            </div>
            <vue-final-modal v-model="showStartAgainModal" classes="modal-container"
                             content-class="content-lightbox edit-popup fancybox-content">
                <div class="holder">
                    <div class="head">
                        <a class="btn-close" href="#" @click.prevent="showStartAgainModal = false">{{
                                $t('Schliessen')
                            }}</a>
                    </div>
                    <article class="body">
                        <div class="txt">
                            <h4>{{ $t('Region ändern') }}</h4>
                            <p v-html='$t("Willst du wirklich die Region ändern?<br>Deine bisherige Produkt-Auswahl im Produkte-Shop geht damit verloren.")'>
                            </p>
                        </div>
                        <div class="btn-holder">
                            <a class="btn btn-default btn-close-popup" href="#"
                               @click.prevent="showStartAgainModal=false">{{ $t('Abbrechen') }}</a>
                            <a class="btn btn-danger" href="#" @click.prevent="onStartAgain">{{
                                    $t('Region ändern')
                                }}</a>
                        </div>
                    </article><!-- / body -->
                </div><!-- / holder -->
            </vue-final-modal>
        </client-only>
    </MainRegion>
</template>

<style scoped>

</style>
