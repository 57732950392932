<script setup>
import Product from "./Product.vue";
import {computed, nextTick, ref, watch} from "vue";
import {PackliConfiguratorStore} from "@/Store/PackliConfigurator.js";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/vue-splide";


const props = defineProps({
    category: Object,
    index: Number,
});

const isCollapsed = ref(true);
const showModal = ref(false);
const productsSlider = ref(null);
const packliConfiguratorStore = PackliConfiguratorStore();

const toggleCollapse = () => {
    packliConfiguratorStore.toggleCategoryCollapse(props.category.id);

    nextTick(() => {
        //check if category is open, then scroll to it
        if (!props.category.collapsed) {
            const categoryHold = document.getElementById(`category-hold-${props.category.id}`);
            if (categoryHold) {
                const headerHeight = 100; // Adjust this value based on your actual header height

                const scrollOptions = {
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest"
                };

                // Calculate the adjusted scroll position by subtracting the header height
                const adjustedScrollPosition = categoryHold.getBoundingClientRect().top - headerHeight;

                // Scroll to the adjusted position
                window.scrollTo({
                    top: window.pageYOffset + adjustedScrollPosition,
                    ...scrollOptions
                });
            }
        }
    });

};

const onShowProductDetails = (index) => {
    showModal.value = true;

    nextTick(() => {
        productsSlider.value.go(index);
        //productsSlider.value.refresh();
    });

};

const sliderOptions = {
    rewind: true,
    pagination: false,
    autoHeight: false,
    width: '100vw',
    breakpoints: {
        992: {
            pagination: true,
            arrows: false,
            height: 'auto',
        },
    }
}

const activeSlide = ref(1);

const onChangeSlide = (index, slide) => {
    activeSlide.value = slide + 1;
}

props.category.products.forEach((product) => {
    watch(() => product.quantity, (newVal, oldVal) => {
        if (newVal !== oldVal) {
            packliConfiguratorStore.updateProductQuantitySequence(props.category.id, product.id);
        }
    });
});

const getCategoryProductsCount = computed(() => {
    return props.category.products.reduce((acc, product) => {
        return acc + product.quantity;
    }, 0);
});

</script>

<template>
    <div :id="'category-hold-' + category.id"
         :class="{'mt-20': index > 0}"
         class="panel panel-default"
    >
        <div class="panel-heading" role="tab">
            <h3 class="panel-title">
                <a :class="{'collapsed': category.collapsed}"
                   href="#"
                   role="button"
                   @click.prevent="toggleCollapse">
											<span class="icon">
                        <img :src="category.dark_icon ? category.dark_icon : '/assets/images/icon-cheese-dark.svg'"
                             alt="#">
                        <img :src="category.white_icon ? category.white_icon : '/assets/images/icon-cheese-white.svg'"
                             alt="#">
                      </span>

                    <span id="category-hold-{{category.id}}" class="hold">
            <strong class="name">
              {{ category.name }}
            </strong>
            <span v-if="getCategoryProductsCount > 0" class="add-str">{{
                    ' ( ' + getCategoryProductsCount + ' ' + $t('Produkt(e) ausgewählt') + ' )'
                }}
            </span>
          </span>
                </a>
            </h3>
        </div><!-- / panel-heading -->
        <div :class="{'collapse': category.collapsed, 'in': !category.collapsed}"
             class="panel-collapse"
             role="tabpanel">
            <div class="panel-body">
                <div class="product-list">
                    <Product v-for="(product, index) in category.products"
                             :key="product.id"
                             :index="index"
                             :product="product" @showProductDetail="onShowProductDetails"/>
                </div><!-- / product-list -->
            </div>
        </div><!-- / panel-collapse -->
    </div><!-- / panel -->


    <vue-final-modal v-model="showModal" classes="modal-container"
                     content-class="content-lightbox fancybox-content">
        <div class="holder">
            <div class="head"><strong>{{ category.name }}</strong> <a class="btn-close" href="#"
                                                                      @click.prevent="showModal=false">{{
                    $t('Schliessen')
                }}</a>
            </div>
            <article class="body">
                <div class="slider-block">
                    <Splide
                        ref="productsSlider"
                        :has-track="false"
                        :options="sliderOptions"
                        :tag="'div'"
                        aria-label="Products"
                        @splide:move="onChangeSlide">
                        <SplideTrack>
                            <SplideSlide v-for="product in category.products" :key="product.id">
                                <div class="slide-item">
                                    <div class="row row-flex row-flex-reverse row-vm">
                                        <div class="col-md-7">
                                            <figure class="image">
                                                <img v-if="product.images && product.images.length > 0"
                                                     :src="product.images[0]"
                                                     alt="image description">
                                                <figcaption class="body-head visible-xs">
                                                    <h4>{{ product.name }}</h4>
                                                    <p>{{ product.description }}</p>
                                                </figcaption>
                                            </figure>
                                        </div>
                                        <div class="col-md-5 column-reverse-xs">
                                            <header class="body-head hidden-xs">
                                                <h4>{{ product.name }}</h4>
                                                <p>{{ product.description }}</p>
                                            </header>
                                            <p v-html="product.inline_description"/>
                                            <div class="qty-form">
                                                <div :class="{'inactive': product.quantity === 0}" class="qty-box">
                                                    <input v-model="product.quantity"
                                                           class="form-control"
                                                           placeholder=""
                                                           type="text">
                                                    <a class="up"
                                                       href="#"
                                                       @click.prevent="product.quantity++">{{ $t('mehr Produkte') }}</a>
                                                    <a class="down"
                                                       href="#"
                                                       @click.prevent="product.quantity > 0 ? product.quantity-- : null">{{
                                                            $t('weniger Produkte')
                                                        }}</a>
                                                </div>
                                            </div>
                                            <div v-if="product.product_declaration"
                                                 class="product-declaration-for-configurator">
                                                <h4 class="product-declaration-header product-declaration-head"
                                                    @click="product.showDetail = !product.showDetail">
                                                    {{ $t('Weitere Angaben zum Produkt') }} <img class="arrow-up"
                                                                                                 :class="{'arrow-down': product.showDetail}"
                                                                                                 src="/assets/images/down-arrow.svg"
                                                                                                 alt="#"></h4>
                                                <transition name="fade">
                                                    <div v-if="product.showDetail"
                                                         class="product-declaration-description"
                                                         v-html="product.product_declaration">
                                                    </div>
                                                </transition>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                        </SplideTrack>

                        <div class="controls splide__arrows">
                            <button aria-label="Previous"
                                    class="slick-prev slick-arrow splide__arrow splide__arrow--prev"
                                    style="" type="button">
                            </button>
                            <div class="state">
                                <span class="current">{{ activeSlide }}</span>/<span class="total">{{
                                    category.products.length
                                }}</span>
                            </div>
                            <button aria-label="Next" class="slick-next slick-arrow splide__arrow splide__arrow--next"
                                    style=""
                                    type="button">
                            </button>
                        </div>
                    </Splide>
                </div>
            </article>
        </div>
    </vue-final-modal>

</template>

<style scoped>
.splide {
    position: inherit !important;
}

.content-lightbox .slider-block .controls {
    bottom: -25px !important;
    right: 100px !important;
}

.vfm__container {
    overflow: scroll !important;
}
</style>
