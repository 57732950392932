<script setup>

import {usePage} from "@inertiajs/vue3";
import {computed, onMounted, ref} from "vue";

const map = ref(null);

const googleMapsApiKey = usePage().props.google_maps_api_key;

const pickupLocation = computed(() => usePage().props.pickup_location);

const GoogleMap = ref(null);
const CustomMarker = ref(null);

onMounted(async () => {
    const {GoogleMap: GMap, CustomMarker: CMarker} = await import('vue3-google-map');
    GoogleMap.value = GMap;
    CustomMarker.value = CMarker;
});

const mapCenter = computed(() => {
    if (pickupLocation.value) {
        return {
            lat: parseFloat(pickupLocation.value.latitude),
            lng: parseFloat(pickupLocation.value.longitude),
        };
    }
    return {
        lat: 0,
        lng: 0,
    };
});

const showMarker = ref(false);

const toggleShowMarker = () => {
    showMarker.value = !showMarker.value;
};

</script>

<template>
    <div class="tab-pane active" id="tabs-02-02">
        <div class="row">
            <div class="col-sm-6">
<!--                <span class="pickup-str">{{ $t('Pickup your package starting 08:00 AM') }}</span>-->
            </div><!-- / col -->
            <div class="col-md-12 date-error-block" style="margin-top: -14px;display: none">
                <em>{{
                        $t('Du hast das Lieferdatum manuell überschrieben. An diesem Tag kann leider kein Päckli geliefert werden.')
                    }}</em>
            </div>
        </div><!-- / row -->
        <h3>{{ $t('Abholadresse') }}</h3>
        <p class="small">{{ pickupLocation.address }}</p>
        <template v-if="GoogleMap && CustomMarker">
            <GoogleMap :api-key="googleMapsApiKey"
                       :center="mapCenter"
                       ref="map"
                       class="map type2"
                       mapTypeId="satellite"
                       :zoom="14">
                <CustomMarker v-if="showMarker" :options="{ position: mapCenter, offsetY : -100 }">
                    <div class="map-tooltip type2">
                        <a href="#" class="close-icon" @click="showMarker=false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="white"
                                 stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </a>
                        <p>
                            {{ pickupLocation.address }}
                        </p>
                    </div>
                </CustomMarker>
                <CustomMarker :options="{ position: mapCenter, anchorPoint:'CENTER'}"
                              v-if="!showMarker"
                              @click="toggleShowMarker">
                    <div style="text-align: center">
                        <img src="/assets/images/icon-marker-large.svg"
                             :class="['custom-marker']"
                             alt="marker"
                        />
                    </div>
                </CustomMarker>

                <CustomMarker :options="{ position: mapCenter, anchorPoint:'CENTER'}"
                              v-if="showMarker"
                              @click="toggleShowMarker">
                    <div style="text-align: center">
                        <img src="/assets/images/icon-marker-large.svg"
                             :class="['custom-marker', 'active-marker']"
                             alt="marker"
                        />
                    </div>
                </CustomMarker>
            </GoogleMap>
        </template>
    </div>
</template>


<style scoped>

.custom-marker {
    height: 60px;
    width: 60px;
}

.active-marker {
    height: 100px;
    width: 100px;
    animation: bounce 1s infinite;
}

</style>
