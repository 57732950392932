<script setup>
import {ref} from 'vue';
import {CartStore} from "@/Store/Cart.js";
import DiscountDetails from "../Common/DiscountDetails.vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n();

const cartStore = CartStore();
const error = ref('');

function validateDiscountCode() {

    error.value = '';

    if (!cartStore.discount_code || cartStore.discount_code.length === 0) {
        error.value = t('Bitte gib einen gültigen Code ein.');
        return;
    }

    if (cartStore.billingAddress.email.length === 0) {
        error.value = t('Bitte gib zuerst deine E-Mail-Adresse im E-Mail-Feld ein.');
        return;
    }

    axios.post(route('discount-voucher.store'), {
        code: cartStore.discount_code,
        email: cartStore.billingAddress.email,
    })
        .then(function (response) {
            cartStore.syncCartWithApi();
        }, function (response) {
            error.value = response.response.data.message;
            cartStore.resetDiscountData();
        })
}
</script>
<template>
    <div>
        <h3>
            <span v-html="$t('Haben Sie einen Gutschein - Code?')"></span>
            <br>
            <span
                v-html="$t('Ein allfälliger Restbetrag wird nach dem Checkout als neuer Gutschein vorhanden sein')"></span>
            <br>
            <br>
            <span v-html="$t('Preise inkl. 2.6% MwSt.')"></span>
        </h3>
        <div v-if="!cartStore.discountData.discount_amount" class="row">
            <div class="col-sm-6 col-md-12 col-lg-6">
                <div class="form-group">
                    <input v-model="cartStore.discount_code"
                           :placeholder="$t('Code eingeben')"
                           class="form-control"
                           name="discount_code"
                           type="text"/>
                </div><!-- / form-group -->
            </div><!-- / col -->
            <div class="col-sm-6 col-md-12 col-lg-6">
                <button
                    class="btn btn-sm btn-block btn-default"
                    type="button"
                    @click="validateDiscountCode"
                >
                    {{ $t('Code einlösen') }}
                </button>
            </div><!-- / col -->
        </div><!-- / row -->
        <div v-if="error" class="col-sm-12 col-md-12 col-lg-12 text-danger" style="margin-bottom: 15px;padding-left: 0">
            <div class="error">
                {{ error }}
            </div>
        </div>
        <DiscountDetails/>
        <div class="well-footer">
            <h4>{{ $t('Total') }}</h4>
            <strong class="total-price">{{ $t('CHF') }} {{ cartStore.cartTotal }}</strong>
        </div><!-- / well-footer -->
    </div>
</template>

<style>
.link-item {
    cursor: pointer;
}
</style>
