<script setup>

import {useForm} from "laravel-precognition-vue-inertia";
import Input from "../Form/Input.vue";
import TextArea from "../Form/TextArea.vue";

let formRoute = null;

if (typeof window !== 'undefined') {
    formRoute = window.route('contact.store');
}

const form = useForm('post', formRoute, {
    email: '',
    name: '',
    message: '',
});

const submit = () => {
    form.submit({
        preserveScroll: true,
        onSuccess: (data) => {
            form.reset();
        },
        onError: () => {
        }
    });
};
</script>

<template>
    <div class="well contact-form">

        <h3>{{ $t('SENDE UNS DEINE NACHRICHT') }}</h3>
        <form @submit.prevent="submit">
            <Input
                :label="$t('Dein Name')"
                name="name"
                v-model="form.name"
                :error="form.errors.name"
                @input="form.validate('name')"/>
            <Input
                :label="$t('Deine E-Mail-Adresse')"
                name="email"
                v-model="form.email"
                :error="form.errors.email"
                @input="form.validate('email')">
            </Input>
            <TextArea
                :label="$t('Deine Nachricht')"
                name="message"
                v-model="form.message"
                :error="form.errors.message"
                @input="form.validate('message')">
          </TextArea>
            <div class="btn-holder">
                <button class="btn btn-block btn-danger" type="submit">
                    {{ $t('NACHRICHT SENDEN') }}
                </button>
            </div>
        </form>
    </div>
</template>

<style scoped>

</style>
