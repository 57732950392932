<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="50">
    <defs>
      <clipPath id="clip_0">
        <rect x="-533" y="-624" width="1920" height="1675" clip-rule="evenodd"/>
      </clipPath>
    </defs>
    <g clip-path="url(#clip_0)">
      <path fill="currentColor" stroke="none"
            d="M39.6444 11.7188L45.6055 11.7188C48.0286 11.7188 50 13.6901 50 16.1133L50 19.043C50 20.9526 48.7753 22.5809 47.0703 23.1855L47.0703 45.6055C47.0703 48.0286 45.0989 50 42.6758 50L7.32422 50C4.90107 50 2.92969 48.0286 2.92969 45.6055L2.92969 23.1855C1.22471 22.5809 0 20.9526 0 19.043L0 16.1133C0 13.6901 1.97139 11.7188 4.39453 11.7188L10.3556 11.7188C9.43389 10.4938 8.88672 8.97178 8.88672 7.32422C8.88672 3.28564 12.1724 0 16.2109 0C18.6437 0 20.4375 0.871582 21.8563 2.74287C23.0434 4.3085 23.9113 6.49434 25 9.25635C26.0887 6.49443 26.9566 4.3085 28.1437 2.74287C29.5625 0.871582 31.3563 0 33.7891 0C37.8276 0 41.1133 3.28564 41.1133 7.32422C41.1133 8.97178 40.5661 10.4938 39.6444 11.7188ZM16.2109 2.92969C19.214 2.92969 20.0671 4.73066 22.3111 10.4238C22.4729 10.8342 22.644 11.2681 22.8235 11.7188L16.2109 11.7188C13.7878 11.7188 11.8164 9.74736 11.8164 7.32422C11.8164 4.90107 13.7878 2.92969 16.2109 2.92969ZM33.7891 2.92969C30.786 2.92969 29.9329 4.73066 27.6889 10.4238C27.5271 10.8342 27.356 11.2681 27.1765 11.7188L33.7891 11.7188C36.2122 11.7188 38.1836 9.74736 38.1836 7.32422C38.1836 4.90107 36.2122 2.92969 33.7891 2.92969ZM19.1406 20.5078L4.39453 20.5078C3.58682 20.5078 2.92969 19.8507 2.92969 19.043L2.92969 16.1133C2.92969 15.3056 3.58682 14.6484 4.39453 14.6484L19.1406 14.6484L19.1406 20.5078ZM22.0703 14.6484L22.0703 47.0703L27.9297 47.0703L27.9297 14.6484L22.0703 14.6484ZM45.6055 20.5078C46.4132 20.5078 47.0703 19.8507 47.0703 19.043L47.0703 16.1133C47.0703 15.3056 46.4132 14.6484 45.6055 14.6484L30.8594 14.6484L30.8594 20.5078L45.6055 20.5078ZM7.32422 47.0703L19.1406 47.0703L19.1406 23.4375L5.85938 23.4375L5.85938 45.6055C5.85938 46.4132 6.5165 47.0703 7.32422 47.0703ZM44.1406 45.6055C44.1406 46.4132 43.4835 47.0703 42.6758 47.0703L30.8594 47.0703L30.8594 23.4375L44.1406 23.4375L44.1406 45.6055Z"
            fill-rule="evenodd"/>
    </g>
  </svg>
</template>

<style scoped>

</style>
