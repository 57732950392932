<script setup>

import MainRegion from "../../Layouts/MainRegion.vue";
import {Link} from "@inertiajs/vue3";
import StickyHeader from "../../Components/Common/StickyHeader.vue";
import SelectedProductsSidebar from "../../Components/Configurator/SelectedProductsSidebar.vue";
import Category from "../../Components/Configurator/Category.vue";
import {PackliConfiguratorStore} from "@/Store/PackliConfigurator";
import {CartStore} from "@/Store/Cart";
import {ref} from "vue";
import ClientOnly from '@duannx/vue-client-only';

const props = defineProps({
    region: Object,
    isMainRegion: Boolean,
    categories: Array,
    package_region: Number,
    uuid: String,
});

const packliConfiguratorStore = PackliConfiguratorStore();
const cartStore = CartStore();
const parentRef = ref();

const categories = props.categories;
let selectedProducts = [];

if (packliConfiguratorStore.getRegion && (packliConfiguratorStore.getRegion.id === props.package_region.id)) {
    selectedProducts = packliConfiguratorStore.getSelectedProducts;
}

if (props.uuid) {
    const cartItem = cartStore.getCartItem(props.uuid);
    if (cartItem) {
        selectedProducts = cartItem.products;
    }
} else {
    console.log('reset');
    packliConfiguratorStore.reset();
    selectedProducts = [];
}

if (selectedProducts.length > 0) {
    categories.forEach((category) => {
        category.products.forEach((product) => {
            const selectedProduct = selectedProducts.find((selectedProduct) => selectedProduct.id == product.id);
            if (selectedProduct) {
                product.quantity = selectedProduct.quantity;
            }
        });
    });
}

packliConfiguratorStore.setCategories(categories);
packliConfiguratorStore.setRegion(props.package_region);
</script>

<template>
    <MainRegion>
        <ClientOnly>
            <div class="extra-bar">
                <div class="container">
                    <Link v-if="isMainRegion"
                          :href="route('configurator.select-region')"
                          class="btn-back">
                        {{ $t('Zurück zur Regionen-Auswahl') }}
                    </Link>
                    <Link v-else
                          :href="route('home')+`#paeckli`"
                          class="btn-back">
                        {{ $t('Zurück zur Übersicht') }}
                    </Link>
                </div>
            </div>
            <div id="configurator">
                <div class="container steps">
                    <h1>{{ $t('Produkte auswählen - zum selber geniessen') }}</h1>
                    <StickyHeader>
                        {{ $t('Produkte auswählen') }}
                        <br>{{
                        $t('Achtung: Individuelle Päckli eignen sich nicht als Geschenk, da nicht alle Produkte in die Geschenkverpackung passen.')
                      }}
                    </StickyHeader>
                    <div id="sticky-container" ref="parentRef" class="step-entity">
                        <div class="row panel-group">
                            <div class="col-md-8">
                                <div id="accordion-01" aria-multiselectable="true" class="" role="tablist">
                                    <Category v-for="(category, index) in packliConfiguratorStore.getCategories"
                                              :key="category.id"
                                              :category="category"
                                              :index="index"/>
                                </div><!-- / panel-group -->
                            </div><!-- / col -->
                            <div class="col-md-4">
                                <SelectedProductsSidebar
                                    :parent-element="parentRef"
                                    :uuid="uuid"/>
                            </div><!-- / col -->
                        </div><!-- / row -->
                    </div><!-- / step-entity -->
                    <header class="step-panel panel-hide">
                        <div class="step-panel-holder">
                            <h2>{{ $t('Lieferinformationen') }}</h2>
                        </div>
                    </header><!-- / step-panel -->
                    <header class="step-panel panel-hide">
                        <div class="step-panel-holder">
                            <h2>{{ $t('Bestellübersicht') }}</h2>
                        </div>
                    </header><!-- / step-panel -->
                    <header class="step-panel panel-hide">
                        <div class="step-panel-holder">
                            <h2>{{ $t('Bezahlung') }}</h2>
                        </div>
                    </header><!-- / step-panel -->
                </div>
            </div>
        </ClientOnly>
    </MainRegion>
</template>

<style scoped>

</style>
