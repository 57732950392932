<template>
  <div class="checkbox">
    <label>
      <input type="hidden" :name="name" value="0"/>
      <input type="checkbox" :name="name" :value="value" @change="onChange" v-model="isChecked"/><span
        class="fake"></span>
      <slot></slot>
    </label>
  </div>
</template>

<script setup>
import {ref} from 'vue';

const props = defineProps({
  name: String,
  value: {
    type: [String, Number, Boolean],
    default: '1',
  },
  checked: {
    type: [String, Number, Boolean],
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

let isChecked = ref(props.checked);

const onChange = (e) => {
  isChecked.value = e.target.checked;
  emit('update:modelValue', isChecked.value);
};
</script>
