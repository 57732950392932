<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="29" height="40">
    <defs>
      <clipPath id="clip_0">
        <rect x="-540" y="-1221" width="1920" height="1675" clip-rule="evenodd"/>
      </clipPath>
    </defs>
    <g clip-path="url(#clip_0)">
      <path fill="currentColor" stroke="none"
            d="M0 14.0625C0 6.30844 6.30844 0 14.0625 0C21.8166 0 28.125 6.30844 28.125 14.0625C28.125 16.6046 27.4391 19.0959 26.1415 21.2672L15.2631 39.4303C15.0532 39.7809 14.6757 39.9968 14.267 40L14.2577 40C13.8526 40 13.4759 39.7906 13.262 39.446L2.09914 21.4572C0.725703 19.2391 0 16.6823 0 14.0625ZM14.24 36.577L24.1302 20.0639C25.2101 18.2569 25.7891 16.1813 25.7891 14.0625C25.7891 7.60078 20.5242 2.32813 14.0625 2.32813C7.60078 2.32813 2.32813 7.60078 2.32813 14.0625C2.32813 16.2461 2.94797 18.3761 4.09117 20.2223L14.24 36.577Z"
            fill-rule="evenodd"/>
    </g>
    <defs>
      <clipPath id="clip_1">
        <rect x="-540" y="-1221" width="1920" height="1675" clip-rule="evenodd"/>
      </clipPath>
    </defs>
    <g clip-path="url(#clip_1)">
      <path fill="currentColor" stroke="none" transform="translate(7.19995 7.19995)"
            d="M0 7.03125C0 3.15422 3.15422 0 7.03125 0C10.9083 0 14.0625 3.15422 14.0625 7.03125C14.0625 10.8412 11.008 14.0625 7.03125 14.0625C3.10289 14.0625 0 10.8835 0 7.03125ZM2.32813 7.03125C2.32813 9.62242 4.43305 11.7344 7.03125 11.7344C9.67063 11.7344 11.7266 9.58461 11.7266 7.03125C11.7266 4.44656 9.61594 2.32813 7.03125 2.32813C4.44656 2.32813 2.32813 4.44656 2.32813 7.03125Z"
            fill-rule="evenodd"/>
    </g>
  </svg>
</template>

<style scoped>

</style>
