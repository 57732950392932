<script setup>
import {ref} from "vue";
import RegionsOnMap from "../Common/RegionsOnMap.vue";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/vue-splide";
import Map from "./Map.vue";
import {Fancybox} from "@fancyapps/ui";
import Dropdown from "../Form/Dropdown.vue";
import ClientOnly from '@duannx/vue-client-only'

const props = defineProps({
    regionsWithSuppliers: {
        type: Array,
        required: true,
    },
    googleMapsApiKey: {
        type: String,
        required: true,
    },
    mapHeader: {
        type: String,
        required: true,
    },
});

const activeRegion = ref(props.regionsWithSuppliers[0]);
const supplierSlider = ref(null);

const onChangeActiveRegion = (regionId, index) => {
    activeRegion.value = props.regionsWithSuppliers[index];
    if (activeRegion.value.suppliers.length > 0) {
        activeSlide.value = 1;
        supplierSlider.value.go(0);
    }
};

const onChangeRegion = (selectedRegion) => {
    onChangeActiveRegion(selectedRegion.id, props.regionsWithSuppliers.findIndex(region => region.id === selectedRegion.id));
};

const sliderOptions = {
    rewind: true,
    pagination: false,
    speed: 600,
    rewindSpeed: 0,
    autoHeight: false,
    width: '100vw',
    type: 'fade',
    breakpoints: {
        992: {
            pagination: props.regionsWithSuppliers.length > 1,
            arrows: false,
            height: 'auto',
        },
    }
}

const activeSlide = ref(1);

const onChangeSlide = (index, slide) => {
    activeSlide.value = slide + 1;
}

const openVideo = (video) => {
    Fancybox.show([
        {
            src: video,
            type: 'iframe',
        },
    ]);
}
const showSupplierDetail = (product) => {
    product.showDetail = !product.showDetail;
}

</script>

<template>
    <div class="container">
        <header class="page-header hidden-sm hidden-xs" v-if="regionsWithSuppliers.length === 1">
            <h2>{{ activeRegion.name }} Produzenten</h2>
        </header>
        <nav class="tabs-bar hidden-sm hidden-xs" v-if="regionsWithSuppliers.length > 1">
            <ul>
                <li v-for="(region, index) in regionsWithSuppliers"
                    :key="index"
                    :class="{'active': region.id === activeRegion.id}">
                    <a @click.prevent="onChangeActiveRegion(region.id, index)"
                       data-toggle="tab">{{ region.name }}</a>
                </li>
            </ul>
        </nav><!-- / tabs-bar -->
        <div
            v-if="regionsWithSuppliers.length > 1"
            class="filter-drop map-filter hidden-md hidden-lg">

            <Dropdown :regions="regionsWithSuppliers"
                      :activeRegion="activeRegion"
                      :label="$t('Supplier')"
                      :items="regionsWithSuppliers"
                      @itemSelected="onChangeRegion"></Dropdown>
        </div><!-- / filter-drop -->
    </div><!-- / container -->
    <div class="tab-content">
        <div class="tab-pane active">
            <div class="container">
                <div class="about-block">
                    <div class="row">
                        <div class="col-md-6 col-md-push-6">
                            <RegionsOnMap :regions="regionsWithSuppliers" :activeRegionId="activeRegion.id"
                                          @onChangeRegion="onChangeActiveRegion"></RegionsOnMap>
                        </div><!-- / col -->
                        <div class="col-md-6 col-md-pull-6">
                            <div class="text-holder">
                                <header class="headline" v-if="regionsWithSuppliers.length > 1">
                                    <figure class="logo"><img :src="activeRegion.logo"
                                                              alt="#"></figure>
                                    <h2>{{ activeRegion.name }} {{ $t('Produzenten') }}</h2>
                                </header>
                                <p v-html="activeRegion.supplier_description"/>
                            </div><!-- / text-holder -->
                        </div><!-- / col -->
                    </div><!-- / row -->
                </div><!-- / about-block -->
            </div><!-- / container -->
            <div class="package-include animated-top">
                <div class="container">
                    <ul class="list-included type2">
                        <li v-for="(supplier, index) in activeRegion.suppliers"
                            :class="{'active': index === activeSlide - 1}"
                            :key="index">
                            <a href="#"
                                class="cursor-pointer"
                                @click.prevent="supplierSlider.go(index)">
                                <figure class="img-hold">
                                    <div class="img bg-img grayscale"
                                         :style="{backgroundImage: 'url(' + supplier.image + ')'}">
                                    </div>
                                </figure>
                                <div class="hold">
                                    <h3 v-html="supplier.name"></h3>
                                </div>
                            </a>
                        </li>
                    </ul><!-- / list-included -->
                </div><!-- / container -->
                <div class="pack-slider type2">
                    <div class="desktop-slider">
                        <Splide
                            :options="sliderOptions"
                            @splide:move="onChangeSlide"
                            :tag="'div'"
                            ref="supplierSlider"
                            :has-track="false"
                            aria-label="Testimonials">
                            <SplideTrack style="height: 100%">
                                <SplideSlide v-for="supplier in activeRegion.suppliers" :key="supplier.id">
                                    <div class="slide-item">
                                        <header class="small-head">
                                            <figure class="img bg-img grayscale"
                                                    :style="{backgroundImage: 'url(' + supplier.image + ')'}">
                                                <img :src="supplier.image" alt="#" class="hidden">
                                            </figure>
                                            <div class="hold">
                                                <h3 v-html="supplier.name"></h3>
                                            </div>
                                        </header><!-- / small-head -->
                                        <div class="entity">
                                            <figure class="bg-img base-img"
                                                    :style="{backgroundImage: 'url(' + supplier.video_image + ')'}">
                                                <a data-fancybox=""
                                                   v-if="supplier.video_url"
                                                   @click.prevent="openVideo(supplier.video_url)"
                                                   :href="supplier.video_url"
                                                   class="btn-play"><i></i>{{ $t('Video ansehen') }}</a>
                                            </figure>
                                            <div class="container">
                                                <div class="descr">
                                                    <header class="large-head">
                                                        <figure class="img-hold">
                                                            <div class="img bg-img grayscale"
                                                                 :style="{backgroundImage: 'url(' + supplier.image + ')'}">
                                                                <img :src="supplier.image" alt="#" class="hidden">
                                                            </div>
                                                        </figure>
                                                        <h3 v-html="supplier.name"></h3>
                                                    </header><!-- / large-head -->
                                                    <p>{{ supplier.description }}</p>
                                                </div><!-- / descr -->
                                            </div><!-- / container -->
                                        </div><!-- / entity -->
                                    </div><!-- / slide-item -->
                                </SplideSlide>
                            </SplideTrack>
                            <div class="controls white splide__arrows">
                                <button class="slick-prev slick-arrow splide__arrow splide__arrow--prev"
                                        aria-label="Previous"
                                        type="button" style="">
                                </button>
                                <div class="state">
                                    <span class="current">{{ activeSlide }}</span>/<span class="total">{{
                                        activeRegion.suppliers.length
                                    }}</span>
                                </div>
                                <button class="slick-next slick-arrow splide__arrow splide__arrow--next"
                                        aria-label="Next" type="button"
                                        style="">
                                </button>
                            </div>
                        </Splide>
                    </div>
                    <div class="mobile-slider">
                        <template v-for="supplier in activeRegion.suppliers" :key="supplier.id">
                            <div class="slide-item"
                                 :class="{'active': supplier.showDetail}"
                            >
                                <header class="small-head"
                                        @click="showSupplierDetail(supplier)">
                                    <figure class="img bg-img grayscale"
                                            :style="{backgroundImage: 'url(' + supplier.image + ')'}">
                                    </figure>
                                    <div class="hold">
                                        <h3 v-html="supplier.name"></h3>
                                    </div>
                                </header><!-- / small-head -->
                                <div class="entity" v-if="supplier.showDetail">
                                    <figure class="bg-img base-img"
                                            :style="{backgroundImage: 'url(' + supplier.video_image + ')'}">
                                        <a data-fancybox=""
                                           v-if="supplier.video_url"
                                           @click.prevent="openVideo(supplier.video_url)"
                                           :href="supplier.video_url" class="btn-play"><i></i>{{ $t('Video ansehen') }}</a>
                                    </figure>
                                    <div class="container">
                                        <div class="descr">
                                            <header class="large-head">
                                                <figure class="img-hold">
                                                    <div class="img bg-img grayscale"
                                                         :style="{backgroundImage: 'url(' + supplier.image + ')'}">
                                                        <img :src="supplier.image" alt="#" class="hidden">
                                                    </div>
                                                </figure>
                                                <h3 v-html="supplier.name"></h3>
                                            </header><!-- / large-head -->
                                            <p>{{ supplier.description }}</p>
                                        </div><!-- / descr -->
                                    </div><!-- / container -->
                                </div><!-- / entity -->
                            </div><!-- / slide-item -->
                        </template>
                    </div>
                </div><!-- / pack-slider -->
            </div><!-- / package-include -->
        </div><!-- / tab-pane -->
    </div>
    <div class="container animated-top map-container1">
        <header class="page-header">
            <h2>{{ mapHeader }}</h2>
        </header><!-- / page-header -->
        <ClientOnly>
            <Map :suppliers="activeRegion.suppliers"
                 :google-maps-api-key="googleMapsApiKey"
            />
        </ClientOnly>
    </div><!-- / container -->
</template>

<style scoped>
.tabs-bar a {
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
