<script setup>
import {useForm} from "laravel-precognition-vue-inertia";
import {PackliConfiguratorStore} from "@/Store/PackliConfigurator.js";
import {onMounted, ref} from "vue";
import ConfiguratorSelectedProducts from "./ConfiguratorSelectedProducts.vue";

const props = defineProps({
    region: Object,
    uuid: String,
    parentElement: Object,
});

const packliConfiguratorStore = PackliConfiguratorStore();
const showModal = ref(false);
const topSpace = ref(100);
const Vue3StickySidebar = ref(null);

const form = useForm('post', route('shop.products.store', {region: packliConfiguratorStore.getRegion.id}), {
    products: [],
    package_region_id: packliConfiguratorStore.getRegion.id,
    uuid: props.uuid,
});

const submit = () => {
    form.products = [...packliConfiguratorStore.getSelectedProductsForCheckout];
    form.submit({
        onSuccess: () => {
            showModal.value = false;
            packliConfiguratorStore.setSelectedProducts(packliConfiguratorStore.getSelectedProductsForCheckout);
        }
    });
};

const closeDialog = () => {
    showModal.value = false;
};

function getVisibleHeightPx(elem) {
    const rect = elem.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Calculate the visible portion of the element
    const visibleTop = elemTop < 0 ? 0 : elemTop;
    const visibleBottom = elemBottom > windowHeight ? windowHeight : elemBottom;

    return visibleBottom - visibleTop;
}

const checkPosition = () => {
    const stickyElement = document.querySelector('.inner-wrapper-sticky');
    const stickyContainer = document.querySelector('#sticky-container');

    if (!stickyElement || !stickyContainer) {
        return;
    }
    const visibleHeight = getVisibleHeightPx(stickyContainer);

    if (stickyElement) {
        const {position} = getComputedStyle(stickyElement);

        // Initialize padding
        let newPaddingTop = `${0}px`;

        if (position === 'fixed') {
            // If body does not have the 'scrolled' class
            if (!document.body.classList.contains('scrolled')) {
                const adjustedElementHeight = stickyElement.offsetHeight;

                if (visibleHeight > 440) {
                    newPaddingTop = `${120}px`;
                }
            }
        }

        // Apply padding change once
        stickyElement.style['paddingTop'] = newPaddingTop;
    }


};

onMounted(async () => {
    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', checkPosition);
        const Vue3StickySidebarComponent = await import('vue3-sticky-sidebar');
        Vue3StickySidebar.value = Vue3StickySidebarComponent.default;
    }
});

const openModal = () => {
    showModal.value = true;
};

</script>

<template>
    <Vue3StickySidebar :bottomSpacing="-50" :topSpacing="topSpace"
                       class="cart-box desktop-only"
                       containerSelector="#sticky-container"
                       innerWrapperSelector='.sidebar__inner'
                       stickyClass="sticked_sidebar">
        <ConfiguratorSelectedProducts @openModal="openModal"/>
    </Vue3StickySidebar>

    <div class="mobile-only cart-box">
        <ConfiguratorSelectedProducts @openModal="openModal"/>
    </div>

    <vue-final-modal v-model="showModal" classes="modal-container"
                     content-class="content-lightbox">
        <div id="confirm-custom-package" class="content-lightbox edit-popup">
            <div class="holder">
                <article class="body">
                    <div class="txt">
                        <p>{{ $t('Preis deines individuellen Päckli inkl. Versandkosten:') }}</p>
                        <p><strong id="final-price">CHF {{
                                packliConfiguratorStore.getSelectedProductsCheckoutTotal
                            }}</strong></p>
                    </div>
                    <div class="btn-holder">
                        <a class="btn btn-default" href="#" @click.prevent="closeDialog">{{ $t('Abbrechen') }}</a>
                        <a class="btn btn-danger" href="#" @click.prevent="submit">{{ $t('In den Warenkorb') }}</a>
                    </div>
                </article><!-- / body -->
            </div><!-- / holder -->
        </div><!-- / region-popup -->
    </vue-final-modal>

</template>

<style>
.btn-delete {
    cursor: pointer;
}

/*
.sticked_sidebar {
    margin-top: 120px!important;
}

.scrolled .sticked_sidebar {
    margin-top: 0!important;
}*/

.inner-wrapper-sticky {
    transition: padding-top 0.3s ease-in-out;
}
</style>
