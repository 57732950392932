<script setup>
import {onMounted, ref} from "vue";
import {v4 as uuid} from "uuid";

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: null,
    },
    error: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    type: {
        type: String,
        default: "text",
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    name: {
        type: String,
    },
    autocomplete: {
        type: String,
        default: "off",
    },
});

const emit = defineEmits(["update:modelValue"]);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute("autofocus")) {
        input.value.focus();
    }
});

const id = "text-input-" + uuid();

const isNumber = (event) => {
    if (props.type === "number") {
        if (
            event.key === "Backspace" ||
            event.key === "Delete" ||
            event.key === "Tab" ||
            event.key === "Escape" ||
            event.key === "." ||
            event.key === "Enter" ||
            (event.key === "ArrowLeft" || event.key === "ArrowRight" || event.key === "ArrowUp" || event.key === "ArrowDown") ||
            (event.key === "Home" || event.key === "End") ||
            (event.ctrlKey === true || event.metaKey === true &&
                (event.key === "a" || event.key === "c" || event.key === "v" || event.key === "x"))
        ) {
            // let it happen, don't do anything
            return;
        }

        // Ensure that it is a number and stop the keypress
        if ((event.which < 48 || event.which > 57) && (event.which < 96 || event.which > 105)) {
            event.preventDefault();
        }
    }
};


</script>

<template>
    <div :class="[$attrs.class, error ? 'form-error' : '']" class="form-group">
        <label v-if="label" :for="id">
            <span v-if="label">{{ label }}<span v-if="required">*</span></span>
            <span v-else><slot/></span>
        </label>
        <div class="relative">
            <input
                ref="input"
                :class="error ? 'error' : ''"
                :disabled="disabled"
                :name="name"
                :type="type"
                :value="modelValue"
                :autocomplete="autocomplete"
                class="form-control"
                @keydown="isNumber($event)"
                v-bind="{ ...$attrs, class: null }" @input="$emit('update:modelValue', $event.target.value)">
        </div>
        <em v-if="error" class="error">{{ error }}</em>
    </div>
</template>
