<script setup>

</script>

<template>
    <div class="maintenance">
        <div class="text-center">
            <svg class="h-4 pb-2 rotate w-6 text-gray-500" fill="none" stroke="currentColor" stroke-width="2"
                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
                <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <h1 class="text-xl md:text-3xl font-bold text-gray-900 dark:text-white">{{
                    $t('Liebe Webseitenbesucherin / Lieber Webseitenbesucher')
                }}</h1>
            <p class="text-md md:text-xl mt-10">
                {{
                    $t('Heute gehen wir mit der neuen Webseite online. Wir sind aktuell an der Umstellung.Die Webseite wird in wenigen Stunden wieder verfügbar sein.')
                }}
            </p>
            <p class="text-md md:text-xl mt-10">{{ $t('Bis später') }}</p>
            <p class="text-md md:text-xl mt-10">{{ $t('Das Päckli Team') }}</p>
        </div>
    </div>
</template>

<style scoped>
.maintenance {
    height: 99vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.maintenance .h-4 {
    height: 8rem;
}

.maintenance .text-gray-500 {

}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotate {
    animation: rotate 4s linear infinite;
}

.pb-2 {
    margin-bottom: 2rem;
}
</style>
