<script setup>
import ItemGiftCard from './ItemGiftCard.vue';
import ItemPackli from './ItemPackli.vue';
import {ITEM_TYPE_GIFT} from "@/constants.js";
import {CartStore} from "@/Store/Cart.js";
import EditGiftCard from "./EditGiftCard.vue";
import {ref} from "vue";
import {router} from '@inertiajs/vue3'
import {useI18n} from "vue-i18n";

const {t} = useI18n();

const cartStore = CartStore();

const props = defineProps({
    items: Array,
});

const editGiftCardModal = ref(null);

const deleteFromCart = (id) => {
    if (confirm(t('Willst du das Produkt wirklich aus dem Warenkorb löschen?'))) {
        cartStore.removeFromCart(id);
    }
    if (cartStore.getItemsInCart.length === 0) {
        cartStore.clearBillingAddress();
        router.get(route('home'));
    }
}

const onEditGiftCard = (item) => {
    editGiftCardModal.value.onEditGiftCard(item);
}

</script>

<template>
    <div v-for="item in items" :id="'item_' + item.id" :key="item.id" class="well" style="padding: 0">
        <div class="order-block">
            <ItemGiftCard v-if="item.item_type === ITEM_TYPE_GIFT"
                          :item="item"
                          @onDeleteFromCart="deleteFromCart"
                          @onEditGiftCard="onEditGiftCard"/>
            <ItemPackli v-else
                        :item="item"
                        @onDeleteFromCart="deleteFromCart"/>
        </div>
        <div v-if="item.gift_message" class="order-gift-message hidden-xs hidden-sm"
             v-html="item.gift_message">
        </div>
    </div>
    <EditGiftCard ref="editGiftCardModal"/>
</template>
