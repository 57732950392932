<script setup>

import {useI18n} from "vue-i18n";

import Dropdown from "../Form/Dropdown.vue";

const {t} = useI18n();

const props = defineProps({
    label: String,
    items: Array,
    defaultLabel: String,
    region_id: Number,
});

const individualItem = {
    id: 0,
    name: t('Päckli individuell zusammen stellen / Geschenkgutschein kaufen'),
};

const allItem = {
    id: -1,
    name: t('Alle anzeigen'),
};

const allItems = [allItem, individualItem, ...props.items];

const emits = defineEmits(['itemSelected']);

function onItemSelected(item) {
    emits('itemSelected', item);
}

</script>
<template>
    <div>
        <!-- other content -->
        <Dropdown :defaultLabel="defaultLabel" :items="allItems" :label="label" :region_id="region_id"
                  @itemSelected="onItemSelected"></Dropdown>
        <!-- other content -->
    </div>
</template>
