<script setup>
import {onMounted, ref} from "vue";
import {v4 as uuid} from "uuid";

defineProps({
  "modelValue": {
    type: [String, Number],
    default: null,
  },
  "error": {
    type: String,
    default: null,
  },
  "label": {
    type: String,
    default: null,
  },
  "type": {
    type: String,
    default: "text",
  },
  "disabled": {
    type: Boolean,
    default: false
  },
  "required": {
    type: Boolean,
    default: false
  }
});

defineEmits(["update:modelValue"]);

const input = ref(null);

onMounted(() => {
  if (input.value.hasAttribute("autofocus")) {
    input.value.focus();
  }
});

const id = "text-input-" + uuid();

</script>

<template>
  <div class="form-group" :class="[error ? 'form-error' : '']">
    <label :for="id" v-if="label">
      <span v-if="label">{{ label }}<span v-if="required">*</span></span>
      <span v-else><slot/></span>
    </label>

    <textarea
        :disabled="disabled"
        class="form-control"
        :class="[$attrs.class]"
        v-bind="{ ...$attrs, class: null }"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)" ref="input"/>

    <em v-if="error" class="error">{{ error }}</em>
  </div>
</template>
