<script setup>

import {computed} from "vue";
import {Link, usePage} from "@inertiajs/vue3";

const region = computed(() => usePage().props.region);

const bgImage = computed(() => {
  if (region.value.package_configurator_image) {
    return region.value.gift_voucher_image;
  } else {
    return '/assets/images/img-product-04.jpg';
  }
});
</script>

<template>
  <div class="product-card"
       :class="[region.gift_voucher_image ? '' : 'with-gift']">
    <div class="img">
      <figure class="bg-img" :style="{backgroundImage: 'url(' + bgImage + ')'}">
        <figcaption v-if="!region.gift_voucher_image">
          <div class="icon"><img src="/assets/images/icon-gift.svg" alt="#"></div>
          <strong class="str">{{ $t('Geschenkgutschein') }}</strong>
        </figcaption>
      </figure>
      <div class="holder">
        <Link
            class="btn btn-info"
            :href="route('gift-card')">{{ $t('Info ansehen') }}
        </Link>
      </div>
    </div><!-- / img -->
    <div class="descr">
      <h3>
        <Link
            :href="route('gift-card')">
          <span v-html="$t('Geschenkgutschein kaufen')"></span>
        </Link>
      </h3>
    </div>
  </div><!-- / product-card -->

</template>

<style scoped>

</style>
