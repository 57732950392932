<script setup>
import {computed, ref} from 'vue'
import {usePage} from "@inertiajs/vue3";
import {useForm} from "laravel-precognition-vue-inertia";
import {ITEM_TYPE_GIFT} from "@/constants.js";
import {CartStore} from "@/Store/Cart.js";
import Input from "../Form/Input.vue";

const props = defineProps({
    init: Object,
    region: Object,
    showModal: Boolean,
})

const cartStore = CartStore();

const region = computed(() => usePage().props.region);

const showModal = ref(props.showModal);

const bgImage = computed(() => {
    if (region.value.gift_voucher_image) {
        return region.value.gift_voucher_image;
    } else {
        return '/assets/images/img-product-03.jpg';
    }
});

const onEditGiftCard = (giftCard) => {
    showModal.value = true;
    //set form price as integer
    form.price = parseInt(giftCard.price);
    form.uuid = giftCard.uuid;
}

defineExpose({onEditGiftCard});

const form = useForm('post', route('packli.cart.store'), {
    item_type: ITEM_TYPE_GIFT,
    price: null,
    region_id: region.value.id,
    uuid: null,
});

const submit = () => {
    form.submit({
        preserveScroll: true,
        onSuccess: () => {
            //update gift card value
            const packliData = form.data();
            cartStore.updateCart(packliData);
            form.reset();
            showModal.value = false;
        },
    });
}

</script>

<template>
    <vue-final-modal v-model="showModal" classes="modal-container"
                     content-class="content-lightbox">
        <div id="gift-popup" class="content-lightbox">
            <div class="holder">
                <div class="head">
                    <strong>{{ $t('Wert des Gutscheins eingeben') }}</strong>
                    <a class="btn-close"
                       href="#"
                       @click.prevent="showModal=false">{{ $t('Schliessen') }}</a>
                </div>
                <article class="body">
                    <form id="giftcard-update" @submit.prevent="submit">
                        <div class="row row-flex-md row-flex-reverse-md">
                            <div class="col-md-7">
                                <div class="body-head visible-xs visible-sm">
                                    <h4>{{ $t('Geschenkgutschein') }}</h4>
                                </div>
                                <div :class="{'product-card': true, 'with-gift': !region.gift_voucher_image}">
                                    <div class="img">
                                        <figure :style="{ backgroundImage: 'url(' + bgImage + ')' }"
                                                class="bg-img">
                                            <figcaption v-if="!region.gift_voucher_image">
                                                <div class="icon"><img alt="#" src="/assets/images/icon-gift.svg"></div>
                                                <strong class="str">{{ 'Geschenkgutschein' }}</strong>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 column-reverse-xs spec-col">
                                <div class="spec-top">
                                    <header class="body-head hidden-xs hidden-sm">
                                        <h4>{{ $t('Geschenkgutschein') }}</h4>
                                    </header>
                                    <p>{{
                                            $t("Ein Päckli Gutschein ist ein tolles Geschenk! Du kannst den Betrag für den Gutschein frei wählen. Direkt nach der Bezahlung erhältst du den Gutschein als PDF, welches du ausdrucken oder per Mail verschicken kannst. Die beschenkte Person kann den Gutschein einfach auf unserer Webseite einlösen und bekommt die Spezialitäten nach Hause geliefert.")
                                        }}</p>
                                </div>
                                <div class="spec-bottom btn-hold">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <Input v-model="form.price"
                                                   :error="form.errors.price"
                                                   :placeholder="'CHF ' + 45"
                                                   name="price"
                                                   required
                                                   type="number" @change="form.validate('price')"/>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <button class="btn btn-block btn-danger" type="submit">
                                                    {{ $t('Wert anpassen') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </article>
            </div>
        </div>
    </vue-final-modal>
</template>
