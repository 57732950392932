<script setup>

import ContactForm from "../Layouts/ContactForm.vue";
import {computed} from "vue";
import {usePage} from "@inertiajs/vue3";

const props = defineProps({
    header: {
        type: String,
        required: true,
    },
    content: {
        type: String,
    }
});

const settings = computed(() => usePage().props.settings);
</script>

<template>
    <div id="contact-us" class="container">
        <div class="contact-section">
            <header class="page-header">
                <h2>{{ header }}</h2>
            </header>
            <!-- / page-header -->
            <div class="about-block">
                <div class="row row-flex-md row-vm">
                    <div class="col-md-6">
                        <div class="text-holder">
                            <p v-html="content"></p>
                            <div class="contact-inf">

                                <p><a :href="'mailto:' + settings['contact_email']">{{ settings['contact_email'] }}</a>
                                </p>

                                <p><a :href="'tel:'+settings['contact_number']">{{ settings['contact_number'] }}</a>
                                    <br>
                                    <a :href="settings['whatsapp']" target="_blank">{{
                                            $t('Du kannst uns auch via WhatsApp kontaktieren.')
                                        }}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <!-- / text-holder -->
                    </div>
                    <!-- / col -->
                    <div class="col-md-6">
                        <ContactForm/>
                        <!-- / well -->
                    </div>
                    <!-- / col -->
                </div>
                <!-- / row -->
            </div>
            <!-- / about-block -->
        </div>
    </div>
</template>

<style scoped>

</style>
