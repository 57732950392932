<script setup>
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import { Link } from '@inertiajs/vue3';
import { onMounted, ref } from "vue";

const props = defineProps({
    subRegions: {
        type: Array,
        required: true,
    },
    header: {
        type: String,
        required: true,
    }
})

let startIndex = 0;
const isBackButtonClicked = ref(false);
const regionSlider = ref(null);

const isClient = typeof window !== 'undefined';

if (isClient) {
    const filter_region = localStorage.getItem('filter_region');
    if (filter_region) {
        const index = props.subRegions.findIndex(region => region.id === parseInt(filter_region));
        if (index !== -1) {
            startIndex = index;
            localStorage.removeItem('filter_region');
        }
    }
}

const sliderOptions = {
    rewind: true,
    pagination: false,
    speed: 600,
    rewindSpeed: 0,
    autoHeight: true,
    width: '100vw',
    type: 'loop',
    start: startIndex,
    height: '760px',
    breakpoints: {
        992: {
            pagination: true,
            arrows: false,
            height: 'auto',
        },
    }
}

const setRegionFilter = (region_id) => {
    if (isClient) {
        localStorage.setItem('filter_region', region_id);
    }
};

onMounted(() => {
    if (isClient) {
        const filter_region = localStorage.getItem('filter_region');
        if (filter_region) {
            const index = props.subRegions.findIndex(region => region.id === parseInt(filter_region));
            if (index !== -1) {
                isBackButtonClicked.value = true;
                regionSlider.value.go(index);
                localStorage.removeItem('filter_region');
            }
        }
    }
});

</script>

<template>
    <div>
        <div class="container animated-top">
            <header class="page-header">
                <h2>{{ header }}</h2>
            </header>
        </div>
        <div class="region-slider-wrap animated-top">
            <Splide
                ref="regionSlider"
                :class="'region-slider'"
                :has-track="false"
                :options="sliderOptions"
                :tag="'div'"
                aria-label="List Regions">
                <SplideTrack style="height: 100%">
                    <SplideSlide v-for="region in subRegions" :key="region.id">
                        <div class="slide-item">
                            <figure :style="{backgroundImage: 'url(' + region.image + ')'}" class="bg-img">
                            </figure>
                            <div class="descr">
                                <header>
                                    <h3>{{ region.name }}</h3>
                                    <div class="logo">
                                        <img :src="region.logo" alt="#">
                                    </div>
                                </header>

                                <div class="txt">
                                    <p v-html="region.description"></p>
                                    <Link
                                        :href="route('packli.index',{filter_region: region.id})"
                                        class="btn btn-danger"
                                        @click="setRegionFilter(region.id)">
                                        {{ $t('ZU DEN') }} {{ region.name.toUpperCase() }}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                </SplideTrack>
                <div class="splide__arrows">
                    <button aria-label="Previous" class="slick-prev slick-arrow splide__arrow splide__arrow--prev"
                            style=""
                            type="button">
                    </button>
                    <button aria-label="Next" class="slick-next slick-arrow splide__arrow splide__arrow--next"
                            style=""
                            type="button">
                    </button>
                </div>

            </Splide>
        </div>
    </div>
</template>

<style scoped>
.splide__slide:not(.is-active) {
    height: 760px !important;
    @media (max-width: 767px) {
        height: auto !important;
    }
}
</style>
