<script setup>

import {computed} from "vue";
import {usePage, Link} from "@inertiajs/vue3";

const region = computed(() => usePage().props.region);
const mainRegion = computed(() => usePage().props.mainRegion);

const bgImage = computed(() => {
  if (region.value.other_region_image) {
    return region.value.other_region_image;
  } else {
    return '/assets/images/img-product-02.jpg';
  }
});

</script>

<template>
  <div class="product-card" data-price="40" data-region="0">
    <div class="img">
      <figure class="bg-img" :style="{backgroundImage: 'url(' + bgImage + ')'}">
      </figure>
      <div class="holder">
        <a :href="mainRegion.url" class="btn btn-info">
          {{ $t('Zur Schweizer Päckli Webseite wechseln') }}
        </a>
      </div>
    </div><!-- / img -->
    <div class="descr">
      <h3>
        <a :href="mainRegion.url">
            {{$t('Päckli aus anderen Regionen:')}}
            <br>
            {{mainRegion.url}}
        </a>
      </h3>
    </div>
  </div><!-- / product-card -->
</template>

<style scoped>

</style>
