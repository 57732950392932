<script setup>
import {nextTick, onBeforeUnmount, onMounted, ref} from "vue";
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';
import {Link} from "@inertiajs/vue3";
import RegionsOnMap from "../Common/RegionsOnMap.vue";

const props = defineProps({
    region: Object,
    isMainRegion: Boolean,
    mainRegion: Object,
    header: String,
    content: String,
    image: String,
    subRegions: Array,
    buttonText: String,
});

const regionSlider = ref(null);

const sliderOptions = {
    rewind: true,
    pagination: false,
    speed: 600,
    rewindSpeed: 0,
    height: '500px',
    arrows: false,
    width: '100%',
    type: 'fade',
    breakpoints: {
        992: {
            pagination: true,
            arrows: false,
            height: 'auto',
        },
    }
}

//if any of the regions has more than 3 suppliers then set height as 500px otherwise 300px
const regionHasMoreThanThreeSuppliers = props.subRegions.some(region => region.suppliers.length > 3);

if (regionHasMoreThanThreeSuppliers) {
    sliderOptions.height = '500px';
} else {
    sliderOptions.height = '300px';
}

const activeRegion = ref(props.subRegions[0]);
const onChangeRegion = (slide, index) => {
    activeRegion.value = props.subRegions[index];
    regionSlider.value.go(index);
}

const onNextSlide = () => {
    regionSlider.value.go('+');
}

const onPreviousSlide = () => {
    regionSlider.value.go('-');
}

const storyRef = ref(null);
const bgImgRef = ref(null);

const screenRes_ = ref(null);

if (typeof window !== 'undefined') {
    screenRes_.value = {
        isDesktop: window.innerWidth > 1024,
        isTablet: window.innerWidth <= 1024 && window.innerWidth > 768,
        isMobile: window.innerWidth <= 768
    }
}

const initStory = () => {
    nextTick(() => {
        if (screenRes_.isTablet || screenRes_.isMobile) {
            bgImgRef.value.style.height = `${storyRef.value.offsetHeight}px`;
        } else {
            bgImgRef.value.style.height = '';
        }
    });
};

let resizeObserver = null;
onMounted(() => {
    if (typeof window !== 'undefined') {
        setTimeout(initStory, 350);

        resizeObserver = new ResizeObserver(() => {
            screenRes_.isDesktop = window.innerWidth > 1024;
            screenRes_.isTablet = window.innerWidth <= 1024 && window.innerWidth > 768;
            screenRes_.isMobile = window.innerWidth <= 768;

            initStory();
        });
        resizeObserver.observe(document.body);
    }
});

onBeforeUnmount(() => {
    if (resizeObserver) {
        resizeObserver.disconnect();
    }
});

</script>

<template>
    <section class="story-section animated-top">
        <figure ref="bgImgRef"
                :style="{backgroundImage: 'url(' + image + ')'}"
                class="bg-img">
            <img :src="image" alt="#"
                 class="hidden">
        </figure>
        <div class="container">
            <article ref="storyRef" class="story">
                <h2>{{ header }}</h2>
                <p v-html="content"></p>
                <footer>
                    <Link href="/produzenten" class="btn btn-danger">{{ buttonText }}</Link>
                </footer>
            </article>

            <div class="region-info">
                <strong class="logo"><img :src="activeRegion.logo" alt="#"></strong>
                <h3>{{ activeRegion.name }}</h3>
                <RegionsOnMap :activeRegionId="activeRegion.id"
                              :regions="subRegions"
                              @onChangeRegion="onChangeRegion"/>
                <Splide
                    ref="regionSlider"
                    :class="'region-slider'"
                    :has-track="false"
                    :options="sliderOptions"
                    :tag="'div'"
                    aria-label="Testimonials"
                    @splide:move="onChangeRegion">
                    <div class="control-dots">
                        <div class="splide__pagination"></div>
                    </div>
                    <div class="services-slider">
                        <SplideTrack>
                            <SplideSlide v-for="(region, index) in subRegions" :key="index" class="slide-item">
                                <div class="hidden">
                                    <strong class="logo"><img :src="region.logo" alt="#"></strong>
                                    <h3>{{ region.name }}</h3>
                                </div>
                                <div class="region-services">
                                    <div v-for="(supplier, supplierIndex) in region.suppliers" :key="supplierIndex"
                                         class="item">
                                        <figure class="img-hold">
                                            <div :style="{backgroundImage: 'url(' + supplier.image + ')'}"
                                                 class="bg-img grayscale">
                                                <img :alt="supplier.name"
                                                     :src="supplier.image"
                                                     class="hidden">
                                            </div>
                                        </figure>
                                        <h4>{{ supplier.name }}</h4>
                                    </div>
                                </div>
                            </SplideSlide>
                        </SplideTrack>
                    </div>
                </Splide>
            </div>
        </div>
        <div v-if="isMainRegion" class="splide__arrows">
            <button aria-label="Previous" class="slick-prev slick-arrow splide__arrow splide__arrow--prev" style=""
                    type="button"
                    @click="onPreviousSlide">
            </button>
            <button aria-label="Next"
                    class="slick-next slick-arrow splide__arrow splide__arrow--next"
                    style="" type="button"
                    @click="onNextSlide">
            </button>
        </div>
    </section>
</template>

<style scoped>

.splide__pagination {
    bottom: auto;
    left: auto;
    position: inherit;
}

</style>
