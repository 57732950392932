<script setup>
import {CartStore} from "@/Store/Cart.js";
import {Link} from "@inertiajs/vue3";
import {ITEM_TYPE_CUSTOM_PACKAGE} from "@/constants.js";

const props = defineProps({
    item: Object,
});

const cartStore = CartStore();

const emit = defineEmits(['onDeleteFromCart']);

const onDeleteFromCart = (id) => {
    emit('onDeleteFromCart', id);
};

let editLink = route('packli.cart.edit', {id: props.item.packli_id, uuid: props.item.uuid});

if (props.item.item_type === ITEM_TYPE_CUSTOM_PACKAGE) {
    editLink += '?package_region_id=' + props.item.region_id;
}

</script>

<template>
    <div class="order-cell base-cell first">
        <header>
            <div class="hold">
                <h3>{{ item.item_type === 'custom_package' ? $t('Individuelles Päckli') : item.item_name }}</h3>
                <a class="btn-delete" href="#"
                   @click.prevent="onDeleteFromCart(item.uuid)">
                    <i aria-hidden="true" class="fa fa-trash-o"></i><span
                    class="sr-only">{{ $t('Element entfernen') }}</span></a>
            </div>
        </header>
        <div class="pack-list">
            <p>
        <span v-for="product in item.products" :key="product.id">
          <span v-if="item.item_type === ITEM_TYPE_CUSTOM_PACKAGE">{{ product.quantity }}x</span> {{
                product.name
            }} <span
            v-if="product.description">({{ product.description }})</span><br/>
        </span>
            </p>
        </div>
    </div><!-- / order-cell -->
    <div class="order-cell second">

        <template v-if="item.delivery_method === 'delivery'">
            <header>
                <h3>{{ $t('Lieferadresse') }}</h3>
            </header>

            <span v-if="item.company">{{ item.company }}<br></span>
            {{ item.first_name }}
            {{ item.last_name }}<br>
            {{ item.street }} {{ item.house_number }}<br>
            {{ item.zip_code }}
            {{ item.city }}<br>
        </template>
        <template v-else>

            <header>
                <h3>{{ $t('Abholadresse') }}</h3>
            </header>

            {{ item.pickup_location }}
        </template>
    </div><!-- / order-cell -->
    <div class="order-cell type2 third">
        <header>
            <h3>
                {{ item.delivery_method === 'delivery' ? $t('LIEFERTERMIN') : $t('ABHOLTERMIN') }}
            </h3>
        </header>
        <time datetime="2018-05-17">{{ item.delivery_date_format }}</time>
        <div class="error">{{ item.shipment_date_error }}</div>
    </div><!-- / order-cell -->
    <div class="header-group fourth">
        <div class="order-cell type2">
            <header>
                <h3>{{ $t('PREIS') }}</h3>
            </header>
            <strong class="price">{{ $t('CHF') }} {{ item.price }}</strong>
        </div><!-- / order-cell -->
        <div class="order-cell hidden-xs hidden-sm">
            <header>
                <Link :href="editLink" class="link">{{ $t('Ändern') }}</Link>
            </header>
            <a class="btn-delete" href="#" @click.prevent="onDeleteFromCart(item.uuid)"><i aria-hidden="true"
                                                                                           class="fa fa-trash-o"></i><span
                class="sr-only">{{ $t('Element entfernen') }}</span></a>
        </div><!-- / order-cell -->
    </div><!-- / header-group -->
    <div v-if="item.gift_message" class="order-gift-message hidden-md hidden-lg" v-html="item.gift_message">
    </div>
    <Link :href="editLink"
          class="btn btn-block btn-default hidden-md hidden-lg">{{
            $t('Ändern')
        }}
    </Link>
</template>

<style scoped>

</style>
