<script setup>
import MainRegion from "../Layouts/MainRegion.vue";

const props = defineProps({
    order: Object,
    has_vouchers: Boolean,
    has_remaining_voucher: Boolean,
    encrypted_order_id: String,
    region: Object,
});

const invoiceLink = route('invoice.show', {'orderId': props.order.id, 'action': 'download'});

const voucherLink = route('voucher.download', {'orderId': props.encrypted_order_id, 'type': 'voucher'});

const remainingVoucherLink = route('voucher.download', {
    'orderId': props.encrypted_order_id,
    'type': 'remaining-voucher'
});

const themeImgThank02 = 'assets/images/img-thank-02.jpg';
const themeImgThank01 = 'assets/images/img-thank-01.jpg';

</script>

<template>
    <MainRegion :simple-footer="true">
        <div class="thank-section">
            <figure class="bg-img"
                    :style="{ backgroundImage: 'url(' + (order.region.thank_you_background ? order.region.thank_you_background : themeImgThank02) + ')' }">
            </figure>
            <div class="container">
                <div class="well thank-box">
                    <div class="txt">
                        <figure class="img-hold">
                            <div class="bg-img img grayscale"
                                 :style="{ backgroundImage: 'url(' + (order.region.thank_you_image ? order.region.thank_you_image : themeImgThank01) + ')' }">
                            </div>
                        </figure>
                        <h2 v-html="$t('Deine Bestellung ist bei uns eingegangen. <br>Vielen Dank!')"></h2>
                        <div class="row btn-holder">
                            <template v-if="has_vouchers">
                                <div class="col-md-6">
                                    <a :href="voucherLink"
                                       class="btn btn-default btn-block">{{ $t('Gutschein herunterladen') }}</a>
                                </div>
                                <div class="col-md-6">
                                    <a :href="invoiceLink"
                                       class="btn btn-danger btn-block">{{ $t('Rechnung herunterladen') }}</a>
                                </div>
                                <div class="col-md-12" v-if="has_remaining_voucher">
                                    <a :href="remainingVoucherLink"
                                       class="btn btn-default btn-block">{{ $t('Gutschein mit Restbetrag herunterladen') }}</a>
                                </div>
                            </template>
                            <template v-else>
                                <template v-if="has_remaining_voucher">
                                    <div class="col-sm-12">
                                        <a :href="invoiceLink" class="btn btn-danger">{{
                                                $t('Rechnung herunterladen')
                                            }}</a>
                                    </div>
                                    <div class="col-sm-12">
                                        <a :href="remainingVoucherLink"
                                           class="btn btn-default">{{ $t('Gutschein mit Restbetrag herunterladen') }}</a>
                                    </div>
                                </template>
                                <template v-else>
                                    <a :href="invoiceLink"
                                       class="btn btn-danger">{{ $t('Rechnung herunterladen') }}</a>
                                </template>
                            </template>
                        </div>
                        <p v-html="$t('Hier kannst du deine Rechnung / Bestellbestätigung herunterladen. Aus ökologischen Gründen versenden wir keine Rechnung per Post.')"></p>
                        <h3 v-html="$t('Wichtig:')"/>
                        <p v-html="$t('Bitte beachte, dass unsere Päckli mit der Schweizerischen Post ausgeliefert werden. Der Briefträger wird das Päckli am gewählten Liefertag in den Milchkasten (Ablagefach) oder vor den Hauseingang legen. An warmen Tagen empfiehlt es sich, das Päckli zügig aus dem Milchkasten zu nehmen.')"></p>
                    </div>
                </div><!-- / thank-box -->
            </div><!-- / container -->
        </div><!--/ thank-section -->
    </MainRegion>
</template>
