import {defineStore} from 'pinia'

export const CartStore = defineStore({
    id: 'CartStore',
    persist: true,

    state: () => ({
        cart: [],
        discount_code: '',
        discountData: {},
        billingAddress: {
            same_as_delivery_address_uuid: '',
            company: '',
            first_name: '',
            last_name: '',
            street: '',
            house_number: '',
            zip_code: '',
            city: '',
            email: '',
            phone: '',
        },
        payment_method: '',
        cartTotal: 0,
    }),

    getters: {
        totalCartItems() {
            return this.cart.length
        }, getItemsInCart() {
            return this.cart
        }, getUniqueAddresses() {
            //return unique addresses from cart, if item_type is 'package' and 'custom_package', also check delivery_method to be 'delivery'.
            //array should contain, company, first_name, last_name, street, house_number, zip_code, city

            return this.cart.filter(item => item.item_type === 'package' || item.item_type === 'custom_package').filter(item => item.delivery_method === 'delivery').map(item => {
                return {
                    company: item.company,
                    first_name: item.first_name,
                    last_name: item.last_name,
                    street: item.street,
                    house_number: item.house_number,
                    zip_code: item.zip_code,
                    city: item.city,
                    uuid: item.uuid
                }
            });
        }
    },

    actions: {
        addToCart(product) {
            product.uuid = new Date().getTime();
            this.cart.push(product);
            this.syncCartWithApi();
        },
        getCartItem(productId) {
            return this.cart.find(product => product.uuid.toString() === productId.toString())
        },
        removeFromCart(productId) {
            this.cart = this.cart.filter(product => product.uuid.toString() !== productId.toString())
            this.syncCartWithApi();
        },

        updateCart(newProductData) {
            const productId = newProductData.uuid;
            const productIndex = this.cart.findIndex(product => product.uuid.toString() === productId.toString())

            if (productIndex >= 0) {
                this.cart[productIndex] = {...this.cart[productIndex], ...newProductData}
            }
            this.syncCartWithApi();
        },

        clearCart() {
            this.cart = [];
            this.billingAddress = {
                same_as_delivery_address_uuid: '',
                company: '',
                first_name: '',
                last_name: '',
                street: '',
                house_number: '',
                zip_code: '',
                city: '',
                email: '',
                phone: '',
            }
            this.payment_method = '';
        },

        setBillingAddress(address) {
            this.billingAddress = {...this.billingAddress, ...address};
        },

        clearBillingAddress() {
            this.billingAddress = {
                ...this.billingAddress,
                same_as_delivery_address_uuid: '',
                company: '',
                first_name: '',
                last_name: '',
                street: '',
                house_number: '',
                zip_code: '',
                city: '',
            }
        },

        setCartItems(items) {
            this.cart = items;
        },

        async syncCartWithApi() {
            if (this.cart.length === 0) return;

            try {
                const response = await axios.post(route('cart.details'), {
                    items: this.cart,
                    discount_code: this.discount_code,
                    email: this.billingAddress.email,
                });

                // Update cart with response data
                this.setCartItems(response.data.cartItems);
                this.cartTotal = response.data.cartTotal;
                this.discountData = response.data.discountData;
                this.discount_code = response.data.discount_code;
            } catch (error) {
                // Handle error here
                console.error(error);
            }
        },
        resetDiscountData() {
            this.discountData = {};
        },
        sameAsAddress(address) {
            this.billingAddress = {...address};
        }
    },
})
