<script setup>
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';
import {onMounted, ref} from 'vue';
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const props = defineProps({
    packli: {
        type: Object,
        default: () => ({}),
    },
});

const main = ref(null);
const thumbs = ref(null);
const slides = props.packli.images.map((image) => ({
    src: image,
    alt: 'Slide',
}));

const mainOptions = {
    type: 'slide',
    perPage: 1,
    perMove: 1,
    arrows: false,
    pagination: false,
    breakpoints: {
        768: {
            pagination: true,
            arrows: false,
            height: 'auto',
        },
    }
};

const thumbsOptions = {
    type: 'slide',
    rewind: false,
    gap: '2rem',
    pagination: false,
    fixedWidth: 110,
    cover: true,
    focus: 'center',
    arrows: true,
    isNavigation: false,
    updateOnMove: false,
};

onMounted(() => {
    const thumbsSplide = thumbs.value?.splide;

    if (thumbsSplide) {
        //main.value?.sync(thumbsSplide);
        activeSlide(0);
    }
});

const emits = defineEmits(['openFancyBox']);

const openFancyBox = (index) => {
    const sliders = props.packli.images.map((image) => {
        return {
            src: image,
            type: "image",
        };
    });
    new Fancybox(sliders, {
        startIndex: index,
        on: {
            done: (fancybox, slide) => {
            },
        },
    });
};

const activeSlide = (index) => {
    main.value?.go(index);
    thumbs.value?.go(index);
    //add class active-slide to index slide li in thumbs slider ref
    const thumbsSlider = thumbs.value?.splide?.root;
    const thumbsSliderLi = thumbsSlider?.querySelectorAll('li');
    thumbsSliderLi.forEach((li) => {
        li.classList.remove('active-slide');
    });
    thumbsSliderLi[index].classList.add('active-slide');
};

</script>

<template>
    <div id="package-slider-01" class="package-slider" data-target="#package-carousel-01">
        <Splide
            ref="main"
            :has-track="false"
            :options="mainOptions"
            aria-labelledby="thumbnail-example-heading"
        >
            <SplideTrack>
                <SplideSlide v-for="(slide,index) in slides" :key="slide.alt">
                    <a class="img" @click="openFancyBox(index)">
                        <div class="slide-item">
                            <figure :style="{backgroundImage: `url(${slide.src})`}" class="bg-img">
                            </figure>
                        </div>
                    </a>
                </SplideSlide>
            </SplideTrack>
            <div class="control-dots">
                <div class="splide__pagination"></div>
            </div>
        </Splide>
    </div>
    <div class="package-carousel">
        <Splide
            ref="thumbs"
            :has-track="false"
            :options="thumbsOptions"
        >
            <SplideTrack>
                <SplideSlide v-for="(slide,index) in slides"
                             :key="slide.alt">
                    <figure :style="{backgroundImage: `url(${slide.src})`}" class="bg-img" @click="activeSlide(index)"/>
                </SplideSlide>
            </SplideTrack>
            <div class="control-dots">
                <div class="splide__pagination"></div>
            </div>
            <div class="splide__arrows">
                <button aria-label="Previous"
                        class="slick-prev slick-arrow splide__arrow thumb-previous splide__arrow--prev"
                        style=""
                        type="button">
                </button>
                <button aria-label="Next"
                        class="slick-next slick-arrow splide__arrow thumb-next splide__arrow--next" style=""
                        type="button">
                </button>
            </div>
        </Splide>
    </div>
</template>


<style scoped>

.package-carousel {
    margin-left: 0;
    margin-right: 0;
}

.package-carousel:before {
    left: 0;
    right: 20px;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
    border: 0;
}

.splide__track--nav > .splide__list > .splide__slide {
    border: 0;
}

.package-carousel .slide-item, .package-carousel .splide__slide {
    padding-right: 0;
}

.package-carousel .slide-item, .package-carousel .splide__slide {
    padding-left: 0;
}

.splide__slide {
    display: block;
}
</style>

<style lang="scss" scoped>
.active-slide {
    &::before {
        opacity: 1 !important;
    }
}

.package-carousel {
    .splide__slide:not(.is-active) {
        height: auto !important;
    }
}

a {
    cursor: pointer;
}

.splide__pagination {
    bottom: auto;
    left: auto;
    position: inherit;
    margin-top: 10px;
}

.thumb-next, .thumb-previous {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.package-carousel .slick-arrow[disabled] {
    color: gray !important;
}

</style>
