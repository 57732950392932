<script setup>
import {computed} from "vue";
import MainRegion from "../Layouts/MainRegion.vue";

const props = defineProps({
    region: Object,
});

const thankYouImage = computed(() => props.region.thank_you_background ? props.region.thank_you_background : '/assets/images/img-thank-02.jpg');

</script>

<template>
    <MainRegion :simple-footer="true">
        <div class="thank-contact-form-section">
            <figure :style="{ backgroundImage: 'url(' + thankYouImage + ')' }" class="bg-img">
            </figure>
            <div class="container">
                <div class="well thank-box">
                    <div class="txt">
                        <h2 v-html="$t('Vielen Dank! Wir melden uns in Kürze.<br>Dein Päckli Team')">
                        </h2>
                    </div>
                </div><!-- / thank-box -->
            </div><!-- / container -->
        </div><!--/ thank-section -->
    </MainRegion>
</template>

<style scoped>

</style>
