<script setup>
import MainRegion from "../Layouts/MainRegion.vue";
import {computed, nextTick, onMounted, onUnmounted} from "vue";
import {Link, router, usePage} from "@inertiajs/vue3";
import {CartStore} from "@/Store/Cart.js";
import BillingAddressForm from "../Components/Checkout/BillingAddressForm.vue";
import StickyHeader from "../Components/Common/StickyHeader.vue";

const packliLink = computed(() => usePage().props.packliLink);
const isMainRegion = computed(() => usePage().props.isMainRegion);
const cartStore = CartStore();

cartStore.syncCartWithApi();

onMounted(() => {
    if (typeof window !== 'undefined') {
        document.body.classList.add('order-page');
        nextTick(() => {
            if (cartStore.getItemsInCart === 0) {
                router.push(route('home'));
            }
        });
    }
});

onUnmounted(() => {
    if (typeof window !== 'undefined') {
        document.body.classList.remove('order-page');
    }
});
</script>

<template>
    <MainRegion :simple-footer="true">
        <div class="container" v-if="cartStore.totalCartItems > 0">
            <h1>{{ $t('Dein Warenkorb') }}</h1>
            <div class="steps">
                <StickyHeader>{{ $t('Bestellübersicht') }}</StickyHeader>
                <div id="cartContainer" class="">
                    <div class="step-entity">
                        <div class="order-overview">
                            <BillingAddressForm/>
                        </div><!-- / order-overview -->
                    </div><!-- / step-entity -->
                    <header class="step-panel">
                        <div class="step-panel-holder">
                            <h2>{{ $t('Bezahlung') }}</h2>
                        </div>
                    </header><!-- / step-panel -->
                </div>
            </div>
        </div>
        <div class="container" v-else>
            <div class="text-center">
                <h1>{{ $t('Dein Warenkorb') }}</h1>
                <p>{{ $t('Dein Warenkorb ist leer.') }}</p>
                <div class="back-button-container">
                    <Link :href="packliLink" class="btn btn-block btn-default">
                        {{ $t('Zurück zum Shop') }}
                    </Link>
                </div>
            </div>
        </div>
    </MainRegion>
</template>


<style scoped>
.back-button-container {
    width: 250px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 80px;
}
</style>
