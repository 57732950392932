<script setup>

import MainRegion from "../Layouts/MainRegion.vue";
import {computed, ref} from "vue";
import {Link, usePage} from "@inertiajs/vue3";
import {useForm} from "laravel-precognition-vue-inertia";
import {ITEM_TYPE_GIFT} from "../constants.js";
import {CartStore} from "../Store/Cart.js";
import Input from "../Components/Form/Input.vue";
import RelatedPackages from "../Components/Common/RelatedPackages.vue";

const isMainRegion = computed(() => usePage().props.isMainRegion);
const cartStore = CartStore();
const showModal = ref(false);
const region = computed(() => usePage().props.region);
const relatedPackages = computed(() => usePage().props.relatedPackages);
const packliLink = computed(() => usePage().props.packliLink);

const bgImage = computed(() => {
    if (usePage().props.region.gift_voucher_image) {
        return usePage().props.region.gift_voucher_image;
    } else {
        return '/assets/images/img-product-03.jpg';
    }
});

const form = useForm('post', route('packli.cart.store'), {
    item_type: ITEM_TYPE_GIFT,
    price: null,
    region_id: region.value.id,
});

const submit = () => {
    form.submit({
        preserveScroll: true,
        onSuccess: () => {
            const packliData = form.data();
            cartStore.addToCart(packliData);
            form.reset();
            showModal.value = true;
        },
    });
}

</script>

<template>
    <MainRegion>
        <div class="extra-bar">
            <div class="container">
                <Link :href="packliLink" class="btn-back">{{
                        $t('Zurück zur Übersicht')
                    }}
                </Link>
            </div><!-- / container -->
        </div><!-- / extra-bar -->
        <div class="container">
            <div class="package-info">
                <div class="col-01">
                    <div class="product-card">
                        <div class="img">
                            <figure :style="{ backgroundImage: 'url(' + bgImage + ')' }"
                                    class="bg-img">
                                <figcaption v-if="!region.gift_voucher_image">
                                    <div class="icon"><img alt="#" src="/assets/images/icon-gift.svg"></div>
                                    <strong class="str">{{ $t('Geschenkgutschein') }}</strong>
                                </figcaption>
                            </figure>
                        </div><!-- / img -->
                    </div><!-- / product-card -->
                </div><!-- / col -->
                <div class="col-02">
                    <header>
                        <h1>{{ $t('Geschenkgutschein') }}</h1>
                    </header>
                    <div class="descr">
                        <p>{{
                                $t("Ein Päckli Gutschein ist ein tolles Geschenk! Du kannst den Betrag für den Gutschein frei wählen. Direkt nach der Bezahlung erhältst du den Gutschein als PDF, welches du ausdrucken oder per Mail verschicken kannst. Die beschenkte Person kann den Gutschein einfach auf unserer Webseite einlösen und bekommt die Spezialitäten nach Hause geliefert.")
                            }}</p>
                    </div>
                    <div class="gift-form">
                        <form id="giftCardForm" @submit.prevent="submit">
                            <label for="lbl-0001">{{ $t('Wert eingeben') }}</label>
                            <div class="row">
                                <div class="col-sm-6">
                                    <Input id="lbl-0001"
                                           v-model="form.price"
                                           :error="form.errors.price"
                                           name="price" placeholder="CHF 20"
                                           type="number"
                                           @change="form.validate('price')"/>
                                </div><!-- / col -->
                                <div class="col-sm-6">
                                    <button class="btn btn-danger btn-block" type="submit">
                                        {{ $t('In den Warenkorb') }}
                                    </button>
                                </div><!-- / col -->
                            </div><!-- / row -->
                        </form>
                    </div><!-- / gift-form -->
                </div><!-- / col -->
            </div><!-- / package-info -->
        </div>
        <RelatedPackages
            :is-main-region="isMainRegion"
            :packages="relatedPackages"
        />
        <VueFinalModal v-model="showModal"
                       class="edit-popup" classes="modal-container"
                       content-class="content-lightbox edit-popup">
            <div id="confirm-package" class="">
                <div class="holder">
                    <article class="body">
                        <div class="txt">
                            <!--<h4>Cart</h4>-->
                            <p>{{ $t('Dein Gutschein ist erfolgreich in den Warenkorb gelegt worden.') }}</p>
                        </div>
                        <div class="btn-holder">
                            <Link :href="packliLink" class="btn btn-default">
                                {{ $t('Noch ein Päckli') }}
                            </Link>
                            <Link :href="route('checkout')" class="btn btn-danger">{{ $t('Zur Bezahlung') }}</Link>
                        </div>
                    </article><!-- / body -->
                </div><!-- / holder -->
            </div><!-- / region-popup -->
        </VueFinalModal>
    </MainRegion>
</template>

<style scoped>

</style>
