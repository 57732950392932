<script setup>
import {computed, nextTick, ref, watch} from 'vue';
import DatePicker from '../Form/DatePicker.vue';

const componentKey = ref(0);

const props = defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    packageRegion: {
        type: Object,
        default: () => ({
            delivery_delay: 0,
            delivery_blacklist_dates: [],
            disabled_order_week_days: [],
            pickup_delay: 0,
            pickup_blacklist_dates: [],
            disabled_pickup_week_days: [],
        }),
    },
    shipmentType: {
        type: String,
        default: 'delivery',
    },
    error: {
        type: String,
        default: null,
    },
});

const emit = defineEmits(['update:modelValue']);

const value = ref(props.modelValue);
const disabledDates = ref([]);
const dateSelectedByUser = ref(false);

if (props.modelValue) {
    dateSelectedByUser.value = true;
}

const addDaysToDate = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

const generateDisabledDates = () => {
    disabledDates.value = [];
    const delay = props.packageRegion[`${props.shipmentType}_delay`];
    const blacklist_dates = props.packageRegion[`${props.shipmentType}_blacklist_dates`] || [];

    for (let i = 0; i < delay; i++) {
        disabledDates.value.push(addDaysToDate(new Date(), i));
    }

    blacklist_dates.forEach((item) => {
        disabledDates.value.push(new Date(item));
    });
};

const disabledWeekDays = computed(() => props.packageRegion[`disabled_${props.shipmentType}_week_days`]);

const findFirstAvailableDate = () => {
    let firstPossibleDate = addDaysToDate(new Date(), props.packageRegion[`${props.shipmentType}_delay`]);

    while (disabledDates.value.find(date =>
        (date.getFullYear() === firstPossibleDate.getFullYear() &&
            date.getMonth() === firstPossibleDate.getMonth() &&
            date.getDate() === firstPossibleDate.getDate())) ||
    disabledWeekDays.value.includes(firstPossibleDate.getDay())) {

        firstPossibleDate = addDaysToDate(firstPossibleDate, 1);
    }

    const day = firstPossibleDate.getDate().toString().padStart(2, '0');
    const month = (firstPossibleDate.getMonth() + 1).toString().padStart(2, '0'); // Because months start from 0
    const year = firstPossibleDate.getFullYear();

    return year + '-' + month + '-' + day;
};

const updateModelValue = () => {

    // If the user hasn't selected a date yet, or the previously selected date is now disabled, set the modelValue to the first available date
    if (!dateSelectedByUser.value) {
        const firstAvailableDate = findFirstAvailableDate();
        emit('update:modelValue', firstAvailableDate);
        value.value = firstAvailableDate;
    } else {
        if (!props.modelValue
            || disabledDates.value.find(date => (date.getFullYear() === new Date(props.modelValue).getFullYear()
                && date.getMonth() === new Date(props.modelValue).getMonth()
                && date.getDate() === new Date(props.modelValue).getDate()))
            || disabledWeekDays.value.includes(new Date(props.modelValue).getDay())) {
            const firstAvailableDate = findFirstAvailableDate();
            emit('update:modelValue', firstAvailableDate);
            value.value = firstAvailableDate;
        }
    }
};

watch(() => props.shipmentType, async () => {
    generateDisabledDates();
    // Using nextTick to wait for the updates in generateDisabledDates to complete
    await nextTick();
    updateModelValue();
    // dirty hack to force the datepicker to rerender, because it doesn't update the disabled dates otherwise (even though the prop is reactive)
    forceRerender();
}, {immediate: true});

updateModelValue();

const onInput = (value) => {
    emit('update:modelValue', value);
    dateSelectedByUser.value = true;
};

const forceRerender = () => {
    componentKey.value += 1;
};

</script>

<template>
    <DatePicker
        :key="componentKey"
        v-model="value"
        :disabled-dates="disabledDates"
        :disabled-week-days="disabledWeekDays"
        :state="!error"
        @date:changed="onInput"
    />
    <em v-if="error" class="form-error-message">{{ error }}</em>
</template>

<style scoped>

</style>
