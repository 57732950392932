<template>
    <svg class="spinner" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle class="semi-transparent" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="semi-opaque"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              fill="currentColor"></path>
    </svg>
</template>

<style scoped>
.spinner {
    animation: spin 1s linear infinite;
    margin-left: -0.25rem;
    margin-right: 0.75rem;
    height: 2rem;
    width: 2rem;
    color: white;
}

.semi-transparent {
    opacity: 0.25;
}

.semi-opaque {
    opacity: 0.75;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
