<script setup>
import {computed, inject} from 'vue';
import Checkbox from "../Form/Checkbox.vue";
import {useForm} from "laravel-precognition-vue-inertia";

const region = inject('region');

const themeImage = '/assets/images/bg-subscribe.jpg';

let formRoute = null;
if (typeof window !== 'undefined') {
    formRoute = route('newsletter.store');
}

const form = useForm('post', formRoute, {
    email: '',
    agree: true,
});

const onSubscribe = () => {
    form.submit({
        preserveScroll: true,
        onSuccess: (data) => {
            form.reset();
            onSubscribeSuccess();
        },
        onError: () => {
        }
    });
};

const onSubscribeSuccess = () => {
    // addMixPanelEvent("Subscribe to newsletter", {
    //   email: email.value,
    // });
};

const logoStyle = computed(() => {
    const image = region.value.news_letter_image ? region.value.news_letter_image : themeImage
    return {
        'background-image': `url(${image})`,
    };
});

const onChangeAgree = (e) => {
    form.agree = e.target.checked;
};

</script>


<template>
    <div class="subscribe-area animated-top">
        <figure :style="logoStyle" class="bg-img">

        </figure>
        <div class="container">
            <div class="subscribe-form">
                <form @submit.prevent="onSubscribe">
                    <h3>{{ $t('Unser Newsletter') }}</h3>
                    <div>
                        <label for="subscribe-email">
                            {{
                                $t('Erhalte Informationen über neue Produkte, Wettbewerbe, Aktionen und vieles mehr aus erster Hand und vor allen anderen.')
                            }}
                        </label>
                        <input
                            id="subscribe-email"
                            v-model="form.email"
                            :placeholder="$t('Deine E-Mail-Adresse')"
                            class="form-control"
                            required
                            type="email"
                        />
                        <em v-if="form.errors.email" class="error">{{ form.errors.email }}</em>
                        <div class="">
                            <Checkbox :checked="form.agree"
                                      data-request="onSameDeliveryAddress"
                                      name="is_same_address"
                                      @change="onChangeAgree">
                                <span v-html="$t('Ja, ich will mich für den Newsletter einschreiben und akzeptiere die <a target=_blank href=/datenschutz>Datenschutzerklärung</a>. Ich kann mich jederzeit wieder vom Newsletter abmelden.')"></span>
                                <em v-if="form.errors.agree" class="error">
                                    <br><br>
                                    {{ form.errors.agree }}
                                </em>
                            </Checkbox>
                        </div>
                        <input :value="$t('ANMELDEN')" class="btn btn-danger" type="submit"/>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
