import {onBeforeUnmount, onMounted} from 'vue';

var screenRes_ = {
    isDesktop: true, isTablet: false, isMobile: false
};

export default function useVisual(textHolder, bgImage) {
    const handleScrollResize = () => {
        if (typeof window !== "undefined") {

            if (window.innerWidth < 768) {
                screenRes_.isDesktop = false;
                screenRes_.isTablet = false;
                screenRes_.isMobile = true;
            }

            let offset, winH, txtOffset, bgOffset, n, txtScale, bgScale, txtTransform, bgTransform;

            if (!screenRes_.isMobile && !screenRes_.isTablet) {
                offset = window.pageYOffset;
                winH = window.innerHeight;
                txtOffset = offset / 2;
                bgOffset = offset / 2.5;
                n = offset / winH;
                txtScale = 1 - .1 * n;
                bgScale = 1 + .3 * n;

                txtTransform = `translateY(${-txtOffset}px)`;
                bgTransform = `scale(${bgScale}) translateY(${bgOffset}px)`;
            } else {
                txtTransform = '';
                bgTransform = '';
            }

            if (textHolder.value && bgImage.value) {
                textHolder.value.style.transform = txtTransform;
                bgImage.value.style.transform = bgTransform;
            }
        }
    };

    onMounted(() => {
        if (typeof window !== "undefined") {
            window.addEventListener('scroll', handleScrollResize);
            window.addEventListener('resize', handleScrollResize);
        }
    });

    onBeforeUnmount(() => {
        if (typeof window !== "undefined") {
            window.removeEventListener('scroll', handleScrollResize);
            window.removeEventListener('resize', handleScrollResize);
        }
    });
}
