<script setup>
import VueMultiselect from 'vue-multiselect';
import {CartStore} from "@/Store/Cart.js";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n();


const value = ref(null);
const cartStore = CartStore();

const autoFillAddress = (option, id) => {
    if (option.uuid === null) {
        cartStore.clearBillingAddress();
        cartStore.billingAddress.same_as_delivery_address_uuid = null;
    } else {
        cartStore.setBillingAddress(option);
        cartStore.billingAddress.same_as_delivery_address_uuid = option.uuid;
    }
    emit('select', option, id);
}

const deliveryAddressPlaceholder = ref(t("Wähle eine Lieferadresse als Rechnungsadresse"));

const getUniqueAddresses = computed(() => {
    const uniqueAddresses = {
        first_name: deliveryAddressPlaceholder.value,
        uuid: null,
    };

    return [uniqueAddresses, ...new Set(cartStore.getUniqueAddresses)];
});

if (cartStore.billingAddress.same_as_delivery_address_uuid) {
    value.value = cartStore.getUniqueAddresses.find(address => address.uuid === cartStore.billingAddress.same_as_delivery_address_uuid);
} else {
    value.value = getUniqueAddresses.value[0];
}

const emit = defineEmits(['select']);


</script>

<template>
    <VueMultiselect v-model="value"
                    :allow-empty="true"
                    :options="getUniqueAddresses"
                    :placeholder="deliveryAddressPlaceholder"
                    :searchable="false"
                    :select-label="deliveryAddressPlaceholder"
                    :show-labels="false"
                    track-by="uuid"
                    @select="autoFillAddress">
        <template #singleLabel="{ option }">
            <div class="flex items-center">
                <span class="mr-2">{{ option.first_name }}
                    <span v-if="option.last_name">&nbsp;{{ option.last_name }}</span>
                </span>
                <span v-if="option.street">,&nbsp;{{ option.street }}, {{ option.city }}, {{ option.zip_code }}</span>
            </div>
        </template>

        <template #option="{ option }">
            <div class="flex items-center">
                <span class="mr-2">{{ option.first_name }}<span v-if="option.last_name">&nbsp;{{
                        option.last_name
                    }}</span>
                </span>
                <span v-if="option.street">,&nbsp;{{ option.street }}, {{ option.city }}, {{ option.zip_code }}</span>
            </div>
        </template>

    </VueMultiselect>
</template>

<style scoped>

</style>
