<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {ref, watch} from "vue";

const minDate = new Date();
const maxDate = new Date();

maxDate.setDate(maxDate.getDate() + 365);

const props = defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    disabledDates: {
        type: Array,
        default: () => [],
    },
    disabledWeekDays: {
        type: Array,
        default: () => [],
    },
});

const emit = defineEmits(['update:modelValue', 'date:changed']);

const onInput = (value) => {
    emit('update:modelValue', value);
    emit('date:changed', value);
};

const value = ref(props.modelValue);

// Watch the modelValue prop and update the value ref whenever it changes.
watch(() => props.modelValue, newVal => {
    value.value = newVal;
});

</script>

<template>
    <VueDatePicker
        v-model="value"
        :clearable="false"
        :disabled-dates="disabledDates"
        :disabled-week-days="disabledWeekDays"
        :enable-time-picker="false"
        :max-date="maxDate"
        :min-date="minDate"
        :month-change-on-scroll="false"
        :transitions="false"
        auto-apply
        format="dd.MM.yyyy"
        locale="de"
        model-type="yyyy-MM-dd"
        month-name-format="long"
        @update:model-value="onInput">
        <template #input-icon>
            <img alt="calendar image" class="calendar-image" src="/assets/images/icon-calendar.svg"/>
        </template>
    </VueDatePicker>
</template>

<style scoped>
.calendar-image {
    margin-left: 16px;
}
</style>

<style global>
.dp__today {
    border: none !important;
}

.dp__today {
    position: relative; /* Ensure the parent is positioned */
}

.dp__today::after {
    content: ''; /* Required for the pseudo-element to show */
    position: absolute; /* Position the triangle relative to its parent */
    bottom: 6px; /* Position it outside the element */
    right: 6px; /* Position it outside the element */
    width: 0;
    height: 0;
    rotate: 270deg;
    border-style: solid;
    border-width: 0 8px 8px 0; /* Adjust the size of the triangle */
    border-color: transparent transparent var(--primary-color) transparent; /* Adjust the color of the triangle */
}

</style>
