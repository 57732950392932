<script setup>
import {computed} from "vue";
import Input from "../Form/Input.vue";
import TextArea from "../Form/TextArea.vue";
import {useForm} from 'laravel-precognition-vue-inertia';
import {usePage} from "@inertiajs/vue3";
import GiftOptionIcon from "../Icons/GiftOptionIcon.vue";
import GiftSelfOptionIcon from "../Icons/GiftSelfOptionIcon.vue";
import DeliveryIcon from "../Icons/DeliveryIcon.vue";
import MarkerIcon from "../Icons/MarkerIcon.vue";
import Checkbox from "../Form/Checkbox.vue";
import PickupLocationMap from "../PackliShipmentDetail/PickupLocationMap.vue";
import ShipmentDateSelector from "./ShipmentDateSelector.vue";
import ClientOnly from '@duannx/vue-client-only';
import {ITEM_TYPE_CUSTOM_PACKAGE, SHIPMENT_TYPE_DELIVERY, SHIPMENT_TYPE_PICKUP} from "@/constants";
import {CartStore} from "@/Store/Cart";
import {PackliConfiguratorStore} from "@/Store/PackliConfigurator";
import Button from "@/Components/Form/Button.vue";

const pickupLocation = computed(() => usePage().props.pickup_location);
const packageRegion = computed(() => usePage().props.package_region);
const itemData = computed(() => usePage().props.item_data);
const itemUuid = computed(() => usePage().props.uuid);
const isEditMode = computed(() => itemUuid.value !== null);

const cartStore = CartStore();
const packliConfiguratorStore = PackliConfiguratorStore();


let formRoute = null;
if (typeof window !== 'undefined') {
    formRoute = route('packli.cart.store');
}

const form = useForm('post', formRoute, {
    delivery_date: "",
    delivery_method: SHIPMENT_TYPE_DELIVERY,
    item_type: itemData.value.item_type,
    packli_id: itemData.value.id,
    first_name: "",
    last_name: "",
    company: "",
    street: "",
    house_number: "",
    zip_code: "",
    city: "",
    country: "",
    pickup_location: "",
    gift_message: "",
    is_gift: true,
    gift_hand_over_personally: false,
    region_id: packageRegion.value.id,
    price: itemData.value.price,
    products: itemData.value.products,
    redirect_to: 'cart',
});


if (itemUuid.value) {
    const item = cartStore.getCartItem(itemUuid.value);
    if (item) {
        //set form data from item
        form.delivery_date = item.delivery_date;
        form.delivery_method = item.delivery_method;
        form.item_type = item.item_type;
        form.packli_id = item.packli_id;
        form.first_name = item.first_name;
        form.last_name = item.last_name;
        form.company = item.company;
        form.street = item.street;
        form.house_number = item.house_number;
        form.zip_code = item.zip_code;
        form.city = item.city;
        form.country = item.country;
        form.pickup_location = item.pickup_location;
        form.gift_message = item.gift_message;
        form.is_gift = item.is_gift;
        form.gift_hand_over_personally = item.gift_hand_over_personally;
        form.region_id = item.region_id;
        form.price = item.price;
        form.products = item.products || [];
    }

    //if custom package set selected products in packli configurator store
    if (form.item_type === ITEM_TYPE_CUSTOM_PACKAGE) {
        packliConfiguratorStore.setSelectedProducts(form.products);
    }
}

const maxLengthForGiftMessage = 500;

const submit = (redirect) => {
    /*form.delivery_date = '02-06-2023';*/
    const uuid = itemUuid.value;
    const packliData = form.data();

    if (form.item_type === ITEM_TYPE_CUSTOM_PACKAGE) {
        form.products = packliConfiguratorStore.getSelectedProducts;
        form.packli_id = 'individuell';
        packliData.products = packliConfiguratorStore.getSelectedProducts;
        packliData.packli_id = 'individuell';
    } else {
        packliData.name = itemData.value.name;
    }

    form.redirect_to = redirect;

    form.submit({
        preserveScroll: true,
        onSuccess: () => {
            if (uuid) {
                packliData.uuid = uuid;
                cartStore.updateCart(packliData);
            } else {
                cartStore.addToCart(packliData);
            }
            form.reset();
        },
    });
}

</script>

<template>
    <form @submit.prevent>
        <div class="row">
            <div class="col-md-4">
                <div class="well delivery-tabs">
                    <header>
                        <h2>{{ $t('Verschenken oder selber geniessen?') }}</h2>
                    </header>
                    <div class="tab-buttons">
                        <input id="isGiftCard" name="is_gift" type="hidden" value="1">
                        <a :class="{'active': form.is_gift}" class="btn-tab gift-card-selection"
                           @click.prevent="form.is_gift = true">
                            <div class="hold">
                                <figure class="icon">
                                    <GiftOptionIcon/>
                                </figure>
                                <p>{{ $t('Ich verschenke das Päckli') }}</p>
                            </div>
                        </a><!-- / btn-tab -->
                        <div v-if="form.is_gift" class="tab-content">
                            <div id="tabs-01-01" class="tab-pane"></div>
                            <div id="tabs-01-02" class="tab-pane active">
                                <Checkbox v-model="form.gift_hand_over_personally">
                                    {{
                                        $t('Ich bestelle das Päckli an meine Adresse und übergebe es dann persönlich.')
                                    }}
                                </Checkbox>
                            </div>
                        </div>
                        <a :class="{'active': !form.is_gift}" class="btn-tab gift-card-selection" data-show-giftcard="0"
                           data-toggle="tab"
                           data-value="0" @click.prevent="form.is_gift = false">
                            <div class="hold">
                                <figure class="icon">
                                    <GiftSelfOptionIcon/>
                                </figure>
                                <p>{{ $t('Das Päckli ist für mich') }}</p>
                            </div>
                        </a><!-- / btn-tab -->
                    </div><!-- / tab-buttons -->
                    <header>
                        <h2>{{ $t('Per Post erhalten oder abholen?') }}</h2>
                    </header>
                    <div class="tab-buttons">
                        <a :class="{'active': form.delivery_method === SHIPMENT_TYPE_DELIVERY}"
                           class="btn-tab delivery-method-selection"
                           data-toggle="tab"
                           data-value="0"
                           @click.prevent="form.delivery_method = SHIPMENT_TYPE_DELIVERY">
                            <div class="hold">
                                <figure class="icon">
                                    <DeliveryIcon style="height: 60px"/>
                                </figure>
                                <p>{{ $t('Päckli per Post an die angegebene Lieferadresse schicken') }}</p>
                            </div>
                        </a><!-- / btn-tab -->

                        <a v-if="pickupLocation"
                           :class="{'active': form.delivery_method === SHIPMENT_TYPE_PICKUP}"
                           class="btn-tab delivery-method-selection"
                           data-toggle="tab"
                           data-value="1"
                           @click.prevent="form.delivery_method = SHIPMENT_TYPE_PICKUP">
                            <div class="hold">
                                <figure class="icon">
                                    <MarkerIcon/>
                                </figure>
                                <p><span v-html="$t('Ich hole das Päckli ab bei: ')"/><br>{{ pickupLocation.address }}
                                </p>
                            </div>
                        </a><!-- / btn-tab -->
                        <input name="pickup_supplier_id" type="hidden" value="{{pickupLocation.id}}"/>

                    </div><!-- / tab-buttons -->
                </div><!-- / delivery-tabs -->
            </div><!-- / col -->
            <div class="col-md-8">
                <div class="well delivery-block">
                    <header>
                        <h2>{{ $t('Details definieren') }}</h2>
                    </header>
                    <div class="tab-content">
                        <div id="tabs-02-01" class="tab-pane active">
                            <h3 v-if="form.delivery_method === SHIPMENT_TYPE_DELIVERY">{{
                                    $t('Lieferdatum auswählen')
                                }}</h3>
                            <h3 v-if="form.delivery_method === SHIPMENT_TYPE_PICKUP">
                                {{ $t('Abholdatum auswählen') }}</h3>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <ShipmentDateSelector
                                            v-model="form.delivery_date"
                                            :error="form.errors.delivery_date"
                                            :package-region="packageRegion"
                                            :shipment-type="form.delivery_method"
                                        />
                                    </div><!-- / form-group -->
                                </div><!-- / col -->
                                <div class="col-md-12 date-error-block" style="margin-top: -14px;display: none">
                                    <em>{{
                                            $t('Du hast das Lieferdatum manuell überschrieben. An diesem Tag kann leider kein Päckli geliefert werden.')
                                        }}</em>
                                </div>
                            </div><!-- / row -->
                            <template v-if="form.delivery_method === SHIPMENT_TYPE_DELIVERY">
                                <h3>Lieferadresse</h3>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <Input v-model="form.company"
                                               :error="form.errors.company"
                                               :label="$t('Firma')"
                                               name="company"
                                               autocomplete="new-company"
                                               @change="form.validate('company')"/>
                                    </div>
                                </div><!-- / row -->
                                <div class="row">
                                    <div class="col-sm-6">
                                        <Input v-model="form.first_name"
                                               :error="form.errors.first_name"
                                               :label="$t('Vorname')"
                                               name="first_name"
                                               required
                                               autocomplete="new-first_name"
                                               @change="form.validate('first_name')"/>
                                    </div><!-- / col -->
                                    <div class="col-sm-6">
                                        <Input v-model="form.last_name"
                                               :error="form.errors.last_name"
                                               :label="$t('Nachname')"
                                               name="last_name"
                                               autocomplete="new-last_name"
                                               required
                                               @change="form.validate('last_name')"/>
                                    </div><!-- / col -->
                                </div><!-- / row -->
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="col-xs-7 col-sm-8">
                                                <Input v-model="form.street"
                                                       :error="form.errors.street"
                                                       :label="$t('Strasse')"
                                                       name="street"
                                                       autocomplete="new-street"
                                                       required
                                                       @change="form.validate('street')"/>
                                            </div><!-- / col -->
                                            <div class="col-xs-5 col-sm-4">
                                                <Input v-model="form.house_number"
                                                       :error="form.errors.house_number"
                                                       :label="$t('Hausnummer')"
                                                       name="house_number"
                                                       autocomplete="new-house_number"
                                                       required
                                                       @change="form.validate('house_number')"/>
                                            </div><!-- / col -->
                                        </div>
                                    </div><!-- / col -->
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="col-xs-5 col-sm-4">
                                                <Input v-model="form.zip_code" :error="form.errors.zip_code"
                                                       :label="$t('PLZ')"
                                                       maxlength="4"
                                                       minlength="4"
                                                       name="zip_code"
                                                       autocomplete="new-zip_code"
                                                       required
                                                       type="number"
                                                       @change="form.validate('zip_code')"></Input>
                                            </div><!-- / col -->
                                            <div class="col-xs-7 col-sm-8">
                                                <Input v-model="form.city" :error="form.errors.city"
                                                       :label="$t('Ort')"
                                                       name="city"
                                                       autocomplete="new-city"
                                                       required
                                                       @change="form.validate('city')"></Input>
                                            </div><!-- / col -->
                                        </div><!-- / row -->
                                    </div><!-- / col -->
                                </div><!-- / row -->
                                <div class="delivery-note">
                                    <p>
                                        <span
                                            v-html='$t("Wichtig: Wir liefern die Päckli nur innerhalb der Schweiz und Liechtenstein. <br/>Die Schweizerische Post deponiert das Päckli im Briefkasten (Milchkasten) oder vor dem Hauseingang.")'></span>
                                        <br><em
                                        class="small">*{{ $t(' Pflichtfelder') }}</em>
                                    </p>
                                </div>
                            </template>
                        </div><!-- / tab-pane -->
                        <client-only>
                            <PickupLocationMap v-if="form.delivery_method === SHIPMENT_TYPE_PICKUP"/>
                        </client-only>
                    </div><!-- / tab-content -->
                    <template v-if="form.is_gift">
                        <div id="gift-message-01" class="gift-message" style="display: block">
                            <h3>{{ $t('Grusskarte') }}*</h3>
                            <div class="form-group">
                                <label class="sr-only" for="gift-message">{{ $t('Grusskartentext') }}</label>
                                <TextArea v-model="form.gift_message"
                                          :error="form.errors.gift_message"
                                          :maxlength="maxLengthForGiftMessage"
                                          :placeholder="$t('Gib bitte den Text ein, der auf der Grusskarte erscheinen soll.')"
                                          cols="30"
                                          rows="10" @change="form.validate('gift_message')"></TextArea>
                                <!-- / form-group -->
                                <div v-if="form.gift_message" id="message_character_count" class="text-right">{{
                                        form.gift_message.length
                                    }}/500
                                </div>
                            </div><!-- / form-group -->
                        </div><!-- / gift-message -->
                    </template>
                </div><!-- / delivery-block -->
                <div class="row btn-holder">
                    <div class="col-sm-6">
                        <Button :disabled="form.processing"
                                :loading="form.processing && form.redirect_to === 'products'"
                                class="btn btn-block btn-default redirect-btn"
                                @click="submit('products')">{{
                                $t('noch ein Päckli hinzufügen')
                            }}
                        </Button>
                    </div><!-- / col -->
                    <div class="col-sm-6">
                        <Button :disabled="form.processing"
                                :loading="form.processing && form.redirect_to === 'cart'"
                                class="btn btn-block btn-danger redirect-btn"
                                type="submit"
                                @click="submit('cart')">
                            <span v-if="isEditMode">{{ $t('Weiter zur Bestellübersicht') }}</span>
                            <span v-else>{{ $t('Weiter zur Bestellübersicht') }}</span>
                        </Button>
                    </div><!-- / col -->
                </div><!-- / row -->
            </div><!-- / col -->
        </div><!-- / row -->
    </form>
</template>

<style scoped>
.gift-card-selection, .delivery-method-selection {
    cursor: pointer;
}
</style>
