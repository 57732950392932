<script setup>

import {ref, watch} from "vue";

const props = defineProps({
  regions: {
    type: Array,
    default: () => []
  },
  activeRegionId: {
    type: Number,
    default: () => 0
  }
});

const emit = defineEmits(['onChangeRegion']);

const onChangeRegion = (region, index) => {
  emit('onChangeRegion', region, index);
};

const activeRegionIdValue = ref(props.activeRegionId);

watch(() => props.activeRegionId, (newValue) => {
  activeRegionIdValue.value = newValue;
});

</script>

<template>
  <div class="map-holder">
    <a v-for="(region, index) in regions"
       :key="index"
       :data-ix="index"
       @click="onChangeRegion(region,index)"
       :class="['link-' + ('0' + region.map_order).slice(-2), {'active': region.id === activeRegionIdValue},'map-region-link']"><span
        class="str map-region-link">{{ region.name }}</span></a>
    <img src="/assets/images/swiss-map.png" alt="#">
  </div>
</template>

<style scoped>
.map-region-link {
  cursor: pointer;
}

</style>
