<script setup>
import {PackliConfiguratorStore} from "@/Store/PackliConfigurator.js";

const packliConfiguratorStore = PackliConfiguratorStore();

const emits = defineEmits(['openModal']);

const openModal = () => {
  emits('openModal');
};

</script>

<template>
  <div>
    <form @submit.prevent="openModal">
      <div class="well">
        <div class="well-head">
          <h3>{{ $t('Dein individuelles Päckli zum selber geniessen') }}</h3>
          <p class="warning" v-if="!packliConfiguratorStore.validForCheckout"
             v-html="$t('Mindestbestellmenge:<br>Bitte wähle mindestens 5 Produkte')">
          </p>
        </div><!-- / well-head -->
        <ul class="cart-list">
          <li v-for="product in packliConfiguratorStore.getSelectedProductsFromCategories" :key="product.id">
            <div class="cell">
              <h4>{{ product.name }}</h4>
              <p>{{ product.description }}</p>
            </div><!-- / cell -->
            <div class="cell">
              <strong v-bind:id="'cart-product-quantity-'+product.id" class="count">{{
                  product.quantity
                }}</strong><strong>&nbsp; X &nbsp;</strong>
              <a @click.prevent="product.quantity = 0" class="btn-delete"><i
                  class="fa fa-trash-o"
                  href="#"
                  aria-hidden="true"></i></a>
            </div><!-- / cell -->
          </li>

          <li v-if="packliConfiguratorStore.getSelectedProductsFromCategories.length === 0">
            <div class="cell">
              <p class="text-center">{{ $t('noch keine Produkte ausgewählt') }}</p>
            </div>
          </li>
        </ul>
        <div class="well-footer" v-if="packliConfiguratorStore.validForCheckout">
          <div class="str">
            <h4>{{ $t('Richtpres') }}</h4>
            <p class="small">{{ $t('(incl Versandkosten)') }}</p>
          </div>
          <strong id="totalPrice" class="total-price">CHF {{
              packliConfiguratorStore.getSelectedProductsCheckoutTotalRange
            }}</strong>
        </div>
      </div><!-- / well -->
      <button :disabled="!packliConfiguratorStore.validForCheckout"
              type="submit"
              class="btn btn-block btn-danger">{{ $t('Preis berechnen und zum Warenkorb hinzufügen') }}
      </button>
    </form>
  </div>
</template>

<style scoped>

</style>
