<script setup>
import {Splide, SplideSlide, SplideTrack} from "@splidejs/vue-splide";
import {computed, ref} from "vue";
import {Link, usePage} from "@inertiajs/vue3";

const props = defineProps({
    packlis: Array
})

const packliLink = computed(() => usePage().props.packliLink);

const thumbsOptions = {
    type: 'loop',
    rewind: false,
    perPage: 3,
    focus: 0,
    pagination: false,
    arrows: false,
    drag: false,
    breakpoints: {
        768: {
            perPage: 1,
            pagination: false,
            arrows: false,
            height: 'auto',
        },
    }
};

const activeSlide = ref(1);
const showAllClients = ref(false);

const onChangeSlide = (index, slide) => {
    activeSlide.value = slide + 1;
}

const toggleShowAllClients = () => {
    showAllClients.value = !showAllClients.value;
}


</script>

<template>
    <div class="container">
        <div class="animated-top">
            <div class="partners-carousel animated-top selected-packli-listing"
                 :class="{'show-all-partners' : showAllClients}">
                <div class="client-slider">
                    <Splide
                        aria-label="The carousel with thumbnails. Selecting a thumbnail will change the main carousel"
                        :options="thumbsOptions"
                        @splide:move="onChangeSlide"
                        :tag="'div'"
                        :has-track="false"
                        ref="thumbs"
                    >
                        <SplideTrack>
                            <SplideSlide v-for="(client,index) in packlis" :key="index">
                                <div class="slide-item">
                                    <Link :href="route('packli.show', {slug: client.id})">
                                        <figure class="partner-box" style="height: 480px">
                                            <div class="logo-box"><img :src="client.image" alt="image"
                                                                       class="fr-fic fr-dii">
                                            </div><!-- end logo-box -->
                                            <figcaption><h3>{{ client.name }}</h3>
                                                <p class="truncate-text" v-html="client.description"></p></figcaption>
                                        </figure><!-- end partner-box -->&nbsp;
                                    </Link>
                                </div>
                            </SplideSlide>
                        </SplideTrack>

                        <div class="controls splide__arrows">
                            <button class="slick-prev slick-arrow splide__arrow splide__arrow--prev"
                                    aria-label="Previous"
                                    type="button" style="">
                            </button>
                            <div class="state">
                                <span class="current">{{ activeSlide }}</span>/<span class="total">{{
                                    packlis.length
                                }}</span>
                            </div>
                            <button class="slick-next slick-arrow splide__arrow splide__arrow--next" aria-label="Next"
                                    type="button"
                                    style="">
                            </button>
                        </div>
                        <div class="mobile-btn-box hidden-xs all-packli-link text-center">
                            <Link class="btn btn-danger cta-all-partners"
                                  :href="packliLink">{{ $t('Alle ansehen') }}
                            </Link>
                        </div>
                    </Splide>
                </div>

                <div class="client-listing">
                    <div v-for="(client,index) in packlis" :key="index"
                         class="slide-item">
                        <Link :href="route('packli.show', {slug: client.id})">
                            <figure class="partner-box">
                                <div class="logo-box"><img :src="client.image" alt="image" class="fr-fic fr-dii"></div>
                                <figcaption><h3>{{ client.name }}</h3>
                                    <p v-html="client.description"></p></figcaption>
                            </figure><!-- end partner-box -->
                        </Link>
                    </div>
                </div>

                <div class="mobile-btn-box visible-xs-block">
                    <Link class="btn btn-danger cta-all-partners"
                          :href="packliLink"
                    >{{ $t('Alle ansehen') }}
                    </Link>
                </div>
                <!-- end mobile-btn-box -->
            </div>
        </div>
    </div>

</template>

<style scoped>
.splide__track--nav > .splide__list > .splide__slide.is-active {
    border: 0;
}

.partner-box .logo-box img {
    height: auto;
}

.truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.partner-box:hover .logo-box img {
    transform: none ! important;
}

.all-packli-link {
    margin-top: 40px;
}

.selected-packli-listing {
    margin-bottom: 0 !important;
}
</style>
