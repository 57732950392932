import {defineStore} from 'pinia'

export const PackliConfiguratorStore = defineStore({
    id: 'PackliConfiguratorStore',
    persist: true, state: () => ({
        categories: [],
        region: null,
        selectedProducts: [],
        updateSequence: 0,
    }),
    getters: {
        getCategories() {
            return this.categories;
        },
        getRegion() {
            return this.region;
        },
        getSelectedProductsTotal() {
            return this.getSelectedProductsForCheckout.reduce((total, product) => {
                return total + (product.price * product.quantity);
            }, 0).toFixed(2);
        },
        getSelectedProducts() {
            return this.selectedProducts;
        },
        getSelectedProductsFromCategories() {
            return this.categories.flatMap(category => {
                return category.products.filter(product => product.quantity > 0);
            }).sort((a, b) => a.lastSequence - b.lastSequence);
        },
        getSelectedProductsForCheckout() {
            return this.getSelectedProductsFromCategories.map(product => {
                return {
                    id: product.id,
                    quantity: product.quantity,
                    name: product.name,
                    weight: product.weight,
                    price: product.price,
                    lastSequence: product.lastSequence,
                }
            }).sort((a, b) => a.lastSequence - b.lastSequence);
        },
        validForCheckout() {
            const selectedProducts = this.getSelectedProductsForCheckout || [];
            const totalQuantity = selectedProducts.reduce((total, product) => {
                return total + product.quantity;
            }, 0);
            return totalQuantity >= 5;
        },
        getSelectedProductsCheckoutTotalRange() {
            const totalPrice = this.getSelectedProductsCheckoutTotal;

            const roundedPrice = Math.round(totalPrice / 5) * 5;

            let finalPrice;

            if (roundedPrice > totalPrice) {
                finalPrice = (roundedPrice - 5) + " - " + roundedPrice;
            } else {
                finalPrice = roundedPrice + " - " + (roundedPrice + 5);
            }

            return finalPrice;
        },
        getSelectedProductsCheckoutTotal() {

            if (this.getSelectedProductsForCheckout.length === 0) {
                return 0;
            }

            let totalWeight = this.getSelectedProductsForCheckout.reduce((total, product) => {
                return total + (product.weight * product.quantity);
            }, 0);  // Note the initial value of 0

            let totalPrice = parseFloat(this.getSelectedProductsTotal);

            totalWeight += parseFloat(this.getRegion.weight_of_box);

            if (totalWeight > 2000) {
                totalPrice += 2;
            }

            totalPrice += parseFloat(this.getRegion.configurator_basic_fee);

            totalPrice = Math.ceil(totalPrice);

            return parseFloat(totalPrice).toFixed(2);
        },

    },
    actions: {
        setCategories(categories) {
            this.categories = categories.map(category => {
                const updatedCategory = {...category, collapsed: true};

                updatedCategory.products = category.products.map(product => {
                    return {...product, quantity: product.quantity || 0, lastSequence: product.lastSequence || 0};
                });
                return updatedCategory;
            });
        },
        setRegion(region) {
            this.region = region;
        },
        setSelectedProducts(products) {
            this.selectedProducts = products;
        },
        toggleCategoryCollapse(categoryId) {
            this.categories.map(category => {
                if (category.id === categoryId) {
                    category.collapsed = !category.collapsed;
                } else {
                    category.collapsed = true;
                }
            })
        },
        reset() {
            this.categories = [];
            this.region = null;
            this.selectedProducts = [];
        },
        updateProductQuantitySequence(categoryId, productId) {
            const category = this.categories.find(cat => cat.id === categoryId);
            if (category) {
                const product = category.products.find(p => p.id === productId);
                if (product) {
                    product.lastSequence = ++this.updateSequence; // Increment and update the sequence
                }
            }
        },
    },
})
