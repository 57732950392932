<script setup>
import {computed, defineProps} from 'vue';
import {Link, usePage} from "@inertiajs/vue3";

const packliLink = computed(() => usePage().props.packliLink);

const props = defineProps({
    packages: Array,
    isMainRegion: Boolean,
})

const themeUrl = '/assets/images/img-product-02.jpg'
</script>

<template>
    <div v-if="packages" class="container related-packages">
        <header class="heading animated-top">
            <h2>{{ $t('Unsere Kunden kauften auch') }}</h2>
            <Link :href="packliLink"
                  class="link hidden-xs">
                {{ $t('Zu allen Päckli') }}
            </Link>
        </header>
        <div class="products type2">
            <div v-for="packli in packages"
                 :key="packli.id"
                 class="product-card">
                <div class="img">
                    <figure :style="{ backgroundImage: 'url(' + (packli.image ? packli.image : themeUrl) + ')' }"
                            class="bg-img">
                    </figure>
                    <div class="holder">
                        <Link
                            :href="route('packli.show', packli.id)"
                            class="btn btn-info">
                            {{ $t('Info ansehen') }}
                        </Link>
                        <Link :href="route('packli.cart', packli.id)"
                              class="btn btn-info">
                            {{ $t('In den Warenkorb') }}
                        </Link>
                    </div>
                </div>
                <div class="descr">
                    <h3>
                        <Link :href="route('packli.show', packli.id)">
                            {{ packli.name }}
                        </Link>
                    </h3>
                    <p>{{ $t('CHF') }} {{ packli.price }}</p>
                </div>
            </div>
            <footer class="btn-holder hidden-lg hidden-md hidden-sm">
                <Link :href="packliLink"
                      class="btn btn-danger">
                    {{ $t('Zu allen Päckli') }}
                </Link>
            </footer>
        </div>
    </div>
</template>
