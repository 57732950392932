<script setup>
import {onMounted, ref, watch} from 'vue';
import SupplierInfoWindow from "./SupplierInfoWindow.vue";

const props = defineProps({
    suppliers: {
        type: Array,
        default: () => []
    },
    googleMapsApiKey: {
        type: String,
        required: true,
    },
});

const infoWindows = ref([]);

const currentIndex = ref(null);
const isActiveMarker = (index) => index === currentIndex.value;

let mapReference = ref(null);
let center = ref({lat: 0, lng: 0});
let zoom = ref(8); // Initial zoom level

const GoogleMap = ref(null);
const Marker = ref(null);
const InfoWindow = ref(null);


onMounted(async () => {
    adjustMapView();
    const {GoogleMap: GMap, Marker: CMarker, InfoWindow: CInfoWindow} = await import('vue3-google-map');
    GoogleMap.value = GMap;
    Marker.value = CMarker;
    InfoWindow.value = CInfoWindow;
});

watch(() => props.suppliers, () => {
    adjustMapView();
}, {immediate: true});

const clickMarkerEvent = (i) => {
    closeInfoWindows(i);
    currentIndex.value = i;
};

function adjustMapView() {
    if (!mapReference.value?.ready) return;

    if (props.suppliers.length === 0) return;

    let latitudes = props.suppliers.map(s => s.latitude);
    let longitudes = props.suppliers.map(s => s.longitude);

    let minLat = Math.min(...latitudes);
    let maxLat = Math.max(...latitudes);
    let minLng = Math.min(...longitudes);
    let maxLng = Math.max(...longitudes);

    // Add some padding to the min and max lat/lng
    let padding = 0.005; // Change this value as needed
    minLat -= padding;
    maxLat += padding;
    minLng -= padding;
    maxLng += padding;

    center.value = {
        lat: (minLat + maxLat) / 2,
        lng: (minLng + maxLng) / 2
    };

    let bounds = new mapReference.value.api.LatLngBounds(
        new mapReference.value.api.LatLng(minLat, minLng),
        new mapReference.value.api.LatLng(maxLat, maxLng)
    );

    mapReference.value.map.fitBounds(bounds);

    let zoomChanged = false;
    const listener = mapReference.value.api.event.addListener(mapReference.value.map, 'bounds_changed', function () {
        if (!zoomChanged) {
            this.setZoom(this.getZoom()); // decrement zoom level by 1
            zoomChanged = true;
        }
    });
}


const closeInfoWindows = (i) => {
    infoWindows.value.forEach((ref, index) => {
        if (index !== i) {
            ref.infoWindow.close();
        }
    });
};

watch(() => mapReference.value?.ready, (ready) => {
    if (!ready) return
    adjustMapView();
})
</script>

<template>
    <template v-if="GoogleMap">
        <div id="map-01" class="map map-margin">
            <GoogleMap
                ref="mapReference"
                :apiKey="googleMapsApiKey"
                :center="center"
                :options="{disableDefaultUI: true}"
                :zoom="zoom"
                class="map map-margin"
                mapTypeId="satellite"
                @click="closeInfoWindows">
                <template v-for="(supplier,i) in suppliers">
                    <Marker
                        :options="{
                        title: supplier.name,
            position: { lat: supplier.latitude, lng: supplier.longitude },
            icon: isActiveMarker(i) ? '/assets/images/icon-marker-large.svg' : '/assets/images/icon-marker.png'
        }"
                        @click="clickMarkerEvent(i)">
                        <InfoWindow
                            :ref="(el) => (infoWindows[i] = el)">
                            <SupplierInfoWindow :supplier="supplier"/>
                        </InfoWindow>
                    </Marker>
                </template>
            </GoogleMap>
        </div>
    </template>
</template>

<style scoped>
.custom-marker {
    height: 60px;
    width: 60px;
}

.active-marker {
    height: 100px;
    width: 100px;
    animation: bounce 1s infinite;
}

.gm-style-iw-c {
    border-radius: 0 !important;
}

.gm-style-iw-d {
    overflow: auto !important;
}

div.map-tooltip {
    width: auto !important;
}
</style>
