<script setup>
import {ref} from "vue";
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';

const props = defineProps({
    testimonials: {
        type: Array,
        required: true,
    },
    header: {
        type: String,
        required: true,
    },
    subheader: {
        type: String,
    }
});

const activeSlide = ref(1);

const onChangeSlide = (index, slide) => {
    activeSlide.value = slide + 1;
}

</script>

<template>
    <section class="testimonials-area animated-top">
        <div class="container">
            <header class="page-header">
                <h2>{{ header }}</h2>
            </header>
            <p v-if="subheader" class="text-center sub-header">
                {{ subheader }}
            </p>
            <div class="testimonials-slider">

                <Splide :has-track="false"
                        :options="{ rewind: true, pagination:false, speed:600, rewindSpeed:0, type: 'loop' }"
                        :tag="'div'"
                        aria-label="Testimonials"
                        @splide:move="onChangeSlide">
                    <div class="custom-wrapper">
                        <SplideTrack>
                            <SplideSlide v-for="(testimonial, index) in testimonials" :key="index"
                                         class="slide-item">
                                <blockquote class="testimonial-item">
                                    <figure :style="{ backgroundImage: 'url(' + testimonial.image + ')' }"
                                            class='bg-img grayscale'></figure>
                                    <cite>
                                        <strong>{{ testimonial.name }}</strong>
                                        <span v-if="testimonial.company">{{ testimonial.company }}</span>
                                    </cite>
                                    <q>{{ testimonial.text }}</q>
                                </blockquote>
                            </SplideSlide>
                        </SplideTrack>
                        <div class="controls splide__arrows">
                            <button aria-label="Previous"
                                    class="slick-prev slick-arrow splide__arrow splide__arrow--prev"
                                    style="" type="button">
                            </button>
                            <div class="state">
                                <span class="current">{{ activeSlide }}</span>/<span
                                class="total">{{ testimonials.length }}</span>
                            </div>
                            <button aria-label="Next" class="slick-next slick-arrow splide__arrow splide__arrow--next"
                                    style=""
                                    type="button">
                            </button>
                        </div>
                    </div>
                </Splide>
            </div>
        </div>
    </section>
</template>

<style scoped>
.sub-header{
    padding-bottom: 0;
    margin-bottom: -30px;
}
</style>
