<script setup>
import {ref} from 'vue';
import {CartStore} from "@/Store/Cart.js";
import {ITEM_TYPE_CUSTOM_PACKAGE, ITEM_TYPE_GIFT} from "@/constants.js";
import DiscountDetails from "../Common/DiscountDetails.vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n();

const cartStore = CartStore();

const total = ref('Total');

const getItemName = (item) => {
    if (item.item_type === ITEM_TYPE_GIFT) return t('Geschenkgutschein');
    if (item.item_type === ITEM_TYPE_CUSTOM_PACKAGE) return t('Individuelles Päckli');
    return item.name;
};
</script>

<template>
    <div class="well order-summary">
        <header>
            <h3>{{ $t('DEIN WARENKORB') }}</h3>
        </header>
        <ul class="list">
            <li v-for="item in cartStore.getItemsInCart" :key="item.uuid">
                <div class="cell">
                    <h4>{{ getItemName(item) }}</h4>
                    <strong class="price">{{ $t('CHF') }} {{ item.price }}</strong>
                </div>
            </li>
            <DiscountDetails/>
        </ul>
        <div class="well-footer">
            <h5>{{ total }}</h5>
            <strong class="total-price">CHF {{ cartStore.cartTotal }}</strong>
        </div>
    </div>
</template>
