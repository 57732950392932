<script setup>
import {Link, usePage} from '@inertiajs/vue3';
import {computed, ref} from "vue";
import MainRegion from "../Layouts/MainRegion.vue";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/vue-splide";
import ProductImageGallery from "../Components/PackliDetail/ProductImageGallery.vue";
import RelatedPackages from "../Components/Common/RelatedPackages.vue";

const props = defineProps({
    isMainRegion: {
        type: Boolean,
        default: false,
    },
    relatedPackages: {
        type: Array,
        default: () => [],
    },
});

const packli = computed(() => usePage().props.package);

const activeSlide = ref(0);

const productsSlider = ref(null);

const sliderOptions = {
    rewind: true,
    pagination: false,
    speed: 800,
    rewindSpeed: 0,
    autoHeight: true,
    arrows: true,
    width: '100%',
    type: 'fade',
    breakpoints: {
        768: {
            pagination: true,
            arrows: false,
            height: 'auto',
        },
    }
}

const onChangeSlide = (index, slide) => {
    activeSlide.value = slide;
}

const changeSlide = (slide) => {
    productsSlider.value.go(slide);
}

const showProductDetail = (product) => {
    product.showDetail = !product.showDetail;
}

</script>

<template>
    <MainRegion>
        <div>
            <div class="extra-bar">
                <div class="container">
                    <Link
                        :href="isMainRegion ? route('packli.index') : route('home')"
                        class="btn-back"
                    >{{ $t('Zurück zur Übersicht') }}
                    </Link>
                </div>
                <!-- / container -->
            </div>
            <!-- / extra-bar -->

            <div class="container">
                <div class="package-info">
                    <div class="col-01">
                        <ProductImageGallery
                            :packli="packli"/>
                        <!--            <div
                                         :key="index">
                                      <a  v-for="(image, index) in packli.images" :href="image" data-fancybox="gallery" :data-src="image" class="img">
                                        <img :src="image" alt="#"/>
                                      </a>
                                    </div>-->
                    </div>
                    <div class="col-02">
                        <header>
                            <h1>{{ packli.name }}</h1>
                            <strong class="price">{{ $t('CHF') }} {{ packli.price }}</strong>
                        </header>
                        <div class="descr">
                            <p v-html="packli.description"></p>
                        </div>
                        <strong class="price">{{ $t('CHF') }} {{ packli.price }}</strong>
                        <template v-if="packli.available_in_shop">
                            <footer class="btn-holder">
                                <Link :href="route('packli.cart',{slug:packli.id})" class="btn btn-danger">
                                    {{ $t('In den Warenkorb') }}
                                </Link>
                            </footer>
                        </template>
                        <template v-else>
                            {{ $t('Derzeit nicht verfügbar') }}
                        </template>
                    </div>
                </div>
            </div>

            <section v-if="packli.products" class="package-include">
                <div class="container">
                    <header class="page-header">
                        <h2>{{ $t('Päckli-Inhalt') }}</h2>
                    </header>
                    <ul class="list-included">
                        <li v-for="(product,index) in packli.products" :key="product.id"
                            :class="{'active': activeSlide === index}">
                            <a href="#" @click.prevent="changeSlide(index)">
                                <figure class="icon">
                                    <img
                                        :src="product.dark_icon ? product.dark_icon : 'assets/images/icon-cheese-dark.svg'"
                                        alt="#"/>
                                    <img
                                        :src="product.white_icon ? product.white_icon : 'assets/images/icon-cheese-white.svg'"
                                        alt="#"/>
                                </figure>
                                <div class="hold">
                                    <h3>{{ product.name }}</h3>
                                    <p>{{ product.description }}</p>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="pack-slider">
                    <div class="desktop-slider">
                        <Splide
                            ref="productsSlider"
                            :has-track="false"
                            :options="sliderOptions"
                            :tag="'div'"
                            aria-label="Testimonials"
                            @splide:move="onChangeSlide">
                            <SplideTrack style="height: 100%">
                                <SplideSlide v-for="(product,index) in packli.products" :key="product.id">
                                    <div class="slide-item">
                                        <header class="small-head">
                                            <figure :style="{backgroundImage: 'url(' + product.background_image + ')'}"
                                                    class="img bg-img grayscale">
                                            </figure>
                                            <div class="hold">
                                                <h3 v-html="product.name"></h3>
                                            </div>
                                        </header><!-- / small-head -->
                                        <div class="entity">
                                            <figure :style="{backgroundImage: 'url(' + product.background_image + ')'}"
                                                    class="bg-img base-img">
                                            </figure>
                                            <div class="container">
                                                <div class="descr">
                                                    <header class="large-head">
                                                        <figure class="icon">
                                                            <img
                                                                :src="product.dark_icon ? product.dark_icon : 'assets/images/icon-cheese-dark.svg'"
                                                                alt="#"/>
                                                            <img
                                                                :src="product.white_icon ? product.white_icon : 'assets/images/icon-cheese-white.svg'"
                                                                alt="#"/>
                                                        </figure>
                                                        <h3>{{ product.name }}</h3>
                                                    </header>
                                                    <p class="product-description" v-html="product.inline_description"/>
                                                    <div v-if="product.product_declaration" class="product-declaration">
                                                        <h4 class="product-declaration-header"
                                                            @click="product.showDetail = !product.showDetail">
                                                            {{ $t('Weitere Angaben zum Produkt') }} <img
                                                            :class="{'arrow-down': product.showDetail}"
                                                            alt="#"
                                                            class="arrow-up"
                                                            src="/assets/images/down-arrow-white.svg">
                                                        </h4>
                                                        <transition name="fade">
                                                            <div v-if="product.showDetail"
                                                                 class="product-declaration-description"
                                                                 v-html="product.product_declaration">
                                                            </div>
                                                        </transition>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- / entity -->
                                    </div><!-- / slide-item -->
                                </SplideSlide>
                            </SplideTrack>
                            <div class="controls white splide__arrows">
                                <button aria-label="Previous"
                                        class="slick-prev slick-arrow splide__arrow splide__arrow--prev"
                                        style="" type="button">
                                </button>
                                <div class="state">
                                    <span class="current">{{ activeSlide + 1 }}</span>/<span class="total">{{
                                        packli.products.length
                                    }}</span>
                                </div>
                                <button aria-label="Next"
                                        class="slick-next slick-arrow splide__arrow splide__arrow--next" style=""
                                        type="button">
                                </button>
                            </div>
                        </Splide>
                    </div>
                    <div class="mobile-slider">
                        <template v-for="(product,index) in packli.products" :key="product.id">
                            <div :class="{'active': product.showDetail}"
                                 class="slide-item">
                                <header class="small-head" @click="showProductDetail(product)">
                                    <figure class="icon">
                                        <img
                                            :src="product.showDetail ? product.white_icon : product.dark_icon"
                                            alt="#"/>
                                    </figure>
                                    <div class="hold">
                                        <h3 v-html="product.name"></h3>
                                    </div>
                                </header><!-- / small-head -->
                                <div v-if="product.showDetail" class="entity">
                                    <figure :style="{backgroundImage: 'url(' + product.background_image + ')'}"
                                            class="bg-img base-img">
                                    </figure>
                                    <div class="container">
                                        <div class="descr">
                                            <header class="large-head">
                                                <figure class="icon">
                                                    <img
                                                        :src="product.dark_icon ? product.dark_icon : 'assets/images/icon-cheese-dark.svg'"
                                                        alt="#"/>
                                                    <img
                                                        :src="product.white_icon ? product.white_icon : 'assets/images/icon-cheese-white.svg'"
                                                        alt="#"/>
                                                </figure>
                                                <h3>{{ product.name }}</h3>
                                            </header>
                                            <p class="product-description" v-html="product.inline_description"/>
                                            <div v-if="product.product_declaration" class="product-declaration">
                                                <h4 class="product-declaration-header">
                                                    {{ $t('Weitere Angaben zum Produkt') }}
                                                </h4>
                                                <div class="product-declaration-description"
                                                     v-html="product.product_declaration">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- / entity -->
                            </div><!-- / slide-item -->
                        </template>
                    </div>
                </div><!-- / pack-slider -->
            </section>
        </div>
        <RelatedPackages
            :is-main-region="isMainRegion"
            :packages="relatedPackages"
        />
    </MainRegion>
</template>

<style scoped>
.col-01 {
    position: relative;
}

a {
    cursor: pointer;
}


</style>

<style>
.fancybox__content {
    width: 100% !important;
    height: 100% !important;
}

.product-description a{
    color: #fff !important;
    text-decoration: underline;
}

.product-description a:hover{
    color: #bbb !important;
    text-decoration: underline;
}
</style>
