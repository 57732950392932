<script setup>
import {onBeforeUnmount, onMounted, ref} from 'vue';
import '@vueform/slider/themes/default.css';
import Slider from '@vueform/slider';

const props = defineProps({
    label: String,
    min: Number,
    max: Number,
    Slider: Slider
});

//set range for slider, set range with multiple of 1000
const rangeForSlider = [Math.floor(props.min / 10) * 10, Math.ceil(props.max / 10) * 10];

let isOpen = ref(false)
let dropdownRef = ref(null)
let range = ref(rangeForSlider);
let slider = ref(null)
const value = ref([props.min, props.max]);

function toggleDropdown() {
    isOpen.value = !isOpen.value
}

function resetRange() {
    range.value = rangeForSlider;
    emits('change', range.value);
}

function handleClickOutside(event) {
    if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
        isOpen.value = false
    }
}

onMounted(() => {
    if (typeof window !== 'undefined') {
        document.addEventListener('click', handleClickOutside)
    }
})

onBeforeUnmount(() => {
    if (typeof window !== 'undefined') {
        document.removeEventListener('click', handleClickOutside)
    }
});

const emits = defineEmits(['change']);

const onChange = (value) => {
    range.value = value;
    emits('change', value);
}

</script>

<template>
    <div ref="dropdownRef" :class="{ open: isOpen }" class="dropdown">
        <a class="dropdown-button" data-toggle="dropdown" @click="toggleDropdown">
            <span class="small-str">{{ label }}</span>
            <span class="str">{{ $t('CHF') }} <span class="value-min">{{ range[0] }}</span> - {{ $t('CHF') }} <span
                class="value-max">{{ range[1] }}</span></span>
            <span class="caret"></span>
        </a>
        <ul class="dropdown-menu">
            <div class="slider-group">
                <div class="text-right">
                    <input :value="$t('zurücksetzen')" class="link-item" type="reset" @click.prevent="resetRange">
                </div>
                <div class="slider-box">
                    <Slider
                        v-model="range"
                        :max="rangeForSlider[1]"
                        :min="rangeForSlider[0]"
                        @change="onChange"/>
                </div>
            </div>
        </ul>
    </div>
</template>

<style>

</style>
<style scoped>

.dropdown-menu {
    /* Add your dropdown menu styles here */
}
</style>
