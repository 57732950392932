<script setup>
import {ref} from "vue";
import useVisual from "../../Composable/useVisual.js";
import Checkbox from "@/Components/Form/Checkbox.vue";

const props = defineProps({
    image: {
        type: String,
        default: ''
    },
    header: {
        type: String,
        default: ''
    },
    content: {
        type: String,
        default: ''
    },
});

const textHolder = ref(null);
const bgImage = ref(null);

useVisual(textHolder, bgImage);

</script>

<template>
    <div class="subscribe-area animated-top overlay-section">
        <figure :style="{backgroundImage: 'url(' + image + ')'}" class="bg-img">
        </figure>
        <div class="container">
            <div class="subscribe-form">
                <h4>{{ header }}</h4>
                <p v-html="content"></p>
            </div>
        </div>
    </div>
</template>


<style scoped>
.overlay-section{
    margin-bottom: 40px;
    margin-top: 80px;
}
</style>
