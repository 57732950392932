<script setup>

</script>

<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
       y="0px"
       viewBox="0 0 50 40.8" xml:space="preserve">
    <path fill="currentColor" d="M43.4,13.7c0,0-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c-1.6-1.6-3.8-2.5-6.2-2.5h0h-8.2V9.1h8.6
	c0.4,0,0.8-0.3,1-0.7l2-6.9c0.1-0.3,0-0.6-0.2-0.9c-0.2-0.3-0.5-0.4-0.8-0.4H27.7c-0.6,0-1,0.4-1,1v0.2v6.7v2.9H13.1h0
	c-0.3,0-0.6,0-0.8,0v0c-4.2,0.4-7.6,3.7-8,7.9H4.1v1v14v1h1h14.3H24v4.8c0,0.6,0.4,1,1,1s1-0.4,1-1v-4.8h18.9h1v-1v-14v0
	C45.9,17.5,44.9,15.3,43.4,13.7z M36.6,7.1h-7.9V2.2H38L36.6,7.1z M6.1,19.9c0-3.6,2.7-6.5,6.2-6.9c3.5,0.4,6.2,3.3,6.2,6.9v13H6.1
	V19.9z M43.9,32.9H20.5v-13v-1h-0.1c-0.3-2.4-1.5-4.5-3.2-6h9.6v5.5h2v-5.5h8.2c1.9,0,3.6,0.8,4.8,2c0,0,0.1,0,0.1,0.1
	c0,0,0.1,0.1,0.1,0.1c1.2,1.3,1.9,3,1.9,4.8V32.9z"/>
</svg>
</template>
