<script setup>
import {computed, inject, ref} from 'vue';
import {Link, router, usePage} from "@inertiajs/vue3";
import {CartStore} from "@/Store/Cart";


const cartStore = CartStore();
const region = inject('region');
const isMainRegion = inject('isMainRegion');
const cartCount = computed(() => usePage().props.cartCount);
const isNavOpen = ref(false);
const packliLink = computed(() => usePage().props.packliLink);

const toggleNav = () => {
    if (typeof window !== 'undefined') {
        isNavOpen.value = !isNavOpen.value;
        //if open add class "opened-menu" to body and remove that class if not
        if (isNavOpen.value) {
            document.body.classList.add('opened-menu');
        } else {
            document.body.classList.remove('opened-menu');
        }
    }
};

if (typeof window !== 'undefined') {
    router.on('start', (event) => {
        setTimeout(() => {
            isNavOpen.value = false;
            document.body.classList.remove('opened-menu');
        }, 200);
    })
}

const getCheckoutLink = computed(() => cartStore.totalCartItems > 0 ? route('checkout') : packliLink.value);

</script>
<template>
    <header id="header">
        <div class="container">
            <div class="navbar-header">
                <Link
                    :href="route('home')"
                    :style="{ background: `url(${region.logo}) no-repeat`, backgroundSize: '100% auto' }"
                    class="navbar-brand"
                >
                    <span class="sr-only">{{ region.name }}</span>
                </Link>
                <div class="btns">
                    <Link :href="getCheckoutLink" class="btn-cart">
                        <i aria-hidden="true" class="fa fa-shopping-cart"></i>
                        <span class="sr-only">{{ $t('WARENKORB') }}</span>
                    </Link>
                    <a class="navbar-toggle" href="#" @click="toggleNav">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <em class="sr-only"></em>
                    </a>
                </div>
            </div>
            <!-- / navbar-header -->
            <nav id="main-nav" :class="{ open: isNavOpen }">
                <ul class="base">
                    <li :class="{ 'active': route().current('packli.index') }">
                        <Link
                            :href="packliLink">
                            {{ $t('Zu den Päckli') }}
                        </Link>
                    </li>
                    <li :class="{ 'active': route().current('suppliers') }">
                        <Link
                            href="/produzenten">{{ $t('Produzenten') }}
                        </Link>
                    </li>
                    <li :class="{ 'active': route().current('business-customers') }">
                        <Link
                            href="/geschaeftskunden">{{ $t('Geschäftskunden') }}
                        </Link>
                    </li>
                    <li :class="{ 'active': route().current('about-us') }">
                        <Link
                            href="/ueber-uns">{{ $t('Über uns') }}
                        </Link>
                    </li>
                </ul>
                <Link v-if="cartStore.totalCartItems > 0" :href="route('checkout')" class="btn btn-shopping">
                    <i aria-hidden="true" class="fa fa-shopping-cart"></i>{{ $t('WARENKORB') }}<span id="cartItems"
                                                                                                     class="badge">{{
                        cartStore.totalCartItems
                    }}</span>
                </Link>
                <Link v-else
                      :href="isMainRegion ? route('packli.index') : route('home')+ '#paeckli'"
                      class="btn btn-shopping"
                >
                    <i aria-hidden="true" class="fa fa-shopping-cart"></i>
                    {{ $t('WARENKORB') }}<span id="cartItems" class="badge">{{ cartStore.totalCartItems }}</span>
                </Link>
            </nav>
            <!-- / main-nav -->
        </div>
        <!-- / container -->
    </header>
    <!-- / header -->
</template>

<style scoped>
#main-nav.open {
    display: flex;
}
</style>

