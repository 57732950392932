<script setup>

import {CartStore} from "@/Store/Cart.js";

const cartStore = CartStore();

const removeDiscount = () => {
    cartStore.discount_code = '';
    cartStore.resetDiscountData();
    cartStore.syncCartWithApi();
}
</script>

<template>
    <div v-if="cartStore.discountData && cartStore.discountData.discount_amount > 0">
        <div class="">
            <ul class="list">
                <li>
                    <div class="cell">
                        <h4 class="cell">{{ cartStore.discountData.title }}</h4>
                        <a class="link-item"
                           href="#"
                           @click.prevent="removeDiscount">
                            {{ $t('LÖSCHEN') }}
                        </a>
                    </div>
                    <strong class="price">- {{ $t('CHF') }} {{ cartStore.discountData.discount_amount }}</strong>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>

</style>
