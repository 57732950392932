import './bootstrap';
import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import "../scss/app.scss";
import {createPinia} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {vfmPlugin} from 'vue-final-modal';
import {createI18n} from 'vue-i18n';
import {ZiggyVue} from 'ziggy-js';
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";
import UserbackPlugin from '@userback/vue';

// Create i18n instance with some options
const i18n = createI18n({
    legacy: false,
    locale: 'de',
    fallbackLocale: 'en',
    messages: {},
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true});
        return pages[`./Pages/${name}.vue`];
    },
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)});

        // Initialize Sentry
        Sentry.init({
            app,
            environment: import.meta.env.VITE_APP_ENV,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                Sentry.browserTracingIntegration()
            ],
            tracesSampleRate: 1.0,
        });

        // Fetch translations from server
        fetch(`/frontend-translations/de.json`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(json => {
                i18n.global.setLocaleMessage('de', json);
                i18n.global.locale.value = 'de';
            })
            .catch(error => console.log(error));

        app.use(plugin)
            .use(i18n)
            .use(pinia)
            .use(ZiggyVue)
            .use(vfmPlugin);

        if (import.meta.env.VITE_USERBACK_TOKEN && import.meta.env.VITE_APP_ENV === 'staging') {
            app.use(UserbackPlugin, {
                token: import.meta.env.VITE_USERBACK_TOKEN
            });
        }

        app.mount(el);
    },
    progress: {
        delay: 250,
        color: 'red',
        includeCSS: true,
        showSpinner: false,
    },
});
