<script setup>
import {computed} from "vue";
import {usePage, Link} from "@inertiajs/vue3";

const region = computed(() => usePage().props.region);
</script>

<template>
    <footer id="simple-footer">
        <div class="container">
            <div class="copyright">
                <p>&copy; {{ new Date().getFullYear() }}, {{ region.name }}</p>
            </div><!-- / copyright -->
            <nav class="footer-menu">
                <ul>
                    <li>
                        <Link :href="'/agb'">{{ $t('AGB') }}</Link>
                    </li>
                    <li>
                        <Link :href="'/datenschutz'">{{ $t('Datenschutzerklärung') }}</Link>
                    </li>
                </ul>
            </nav><!-- / footer-menu -->
        </div><!-- / container -->
    </footer><!-- / simple-footer -->
</template>

<style scoped>

</style>
