<script setup>

import {computed} from "vue";
import {usePage, Link} from "@inertiajs/vue3";

const region = computed(() => usePage().props.region);
const isMainRegion = computed(() => usePage().props.isMainRegion);

const bgImage = computed(() => {
  if (region.value.package_configurator_image) {
    return region.value.package_configurator_image;
  } else {
    return '/assets/images/img-product-04.jpg';
  }
});

let formRoute = null;
if (typeof window !== 'undefined') {
    formRoute = isMainRegion.value ? route('configurator.select-region') : route('shop.products', {region: region.value.id});
}

const shopRoute = formRoute;

</script>

<template>
  <div class="product-card" data-price="40" data-region="0">
    <div class="img">
      <figure class="bg-img" :style="{backgroundImage: 'url(' + bgImage + ')'}">
      </figure>
      <div class="holder">
        <Link :href="shopRoute"
              class="btn btn-info">
          {{ $t('Info ansehen') }}
        </Link>
      </div>
    </div><!-- / img -->
    <div class="descr">
      <h3>
        <Link :href="shopRoute">
          <span v-html="$t('Päckli individuell zusammenstellen zum selber geniessen')"></span>
        </Link>
      </h3>
    </div>
  </div><!-- / product-card -->
</template>

<style scoped>

</style>
