<script setup>
import {computed, ref} from 'vue';
import VStickyElement from "vue-sticky-element";
import PriceRangeFilter from "./PriceRangeFilter.vue";
import FilterDropdown from "./FilterDropdown.vue";
import {usePage} from "@inertiajs/vue3";

const props = defineProps({
    regions: {
        type: Array,
    },
    filters: {
        type: Object,
    },
});

const packliMinPrice = props.filters.min_price;
const packliMaxPrice = props.filters.max_price;

const filters = ref(props.filters);

const onPriceRangeChange = (value) => {
    filters.value.min_price = value[0];
    filters.value.max_price = value[1];
    emits('filterChanged', filters.value);
};

const onRegionSelected = (value) => {
    filters.value.region_id = value.id;
    emits('filterChanged', filters.value);
};

const packlis = computed(() => usePage().props.packlis);

const emits = defineEmits(['filterChanged']);

</script>

<template>
    <div class="filter-wrap">
        <VStickyElement stickMode="element-end"
                        stuckClass="is_stuck"
                        style="height: auto!important;">
            <div class="filter filter-drop">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6">
                            <FilterDropdown :items="regions"
                                            :label="$t('Wähle eine Region')"
                                            :region_id="filters.region_id"
                                            defaultLabel="All"
                                            @itemSelected="onRegionSelected"/>
                        </div>
                        <div class="col-sm-6">
                            <PriceRangeFilter
                                :label="$t('Preisspanne')"
                                :max="packliMaxPrice"
                                :min="packliMinPrice" @change="onPriceRangeChange"/>
                        </div>
                    </div>
                </div>
            </div>
        </VStickyElement>
    </div>
</template>


<style scoped>

.filter .container {
    margin-left: -10px;
}

.is_stuck .container {
    margin: 0 auto;
}
</style>
